/* eslint-disable react-hooks/exhaustive-deps */
import Axios, {
  AxiosBasicCredentials,
  AxiosInstance,
  AxiosProxyConfig,
  AxiosRequestConfig,
} from 'axios';
import { KeycloakInstance } from 'keycloak-js';
import * as React from 'react';
import { HttpErrorModel } from '../models/http-error';
import { log } from '../utils/log';
import { objectFindValueFromSomeKey } from '../utils/object/object-find-value-from-some-key';
import { objectToMap } from '../utils/object/object-to-map';
import { useAuth } from './auth';

type RequestParamValue = string | number | boolean | undefined;

export type RequestParams =
  | { [key: string]: RequestParamValue }
  | Map<string, RequestParamValue>
  | URLSearchParams;

function sanitizeUrl(url: string): string {
  return url.trim().replace(/\/$/, '');
}

function configFactory(config?: HookHttpConfigInterface): AxiosRequestConfig {
  const axiosConfig: AxiosRequestConfig = {};
  if (!!config?.headers) {
    axiosConfig.headers = config.headers;
  }
  if (!!config?.baseUrl) {
    axiosConfig.baseURL = sanitizeUrl(config.baseUrl);
  }
  if (!!config?.withCredentials) {
    axiosConfig.withCredentials = config.withCredentials;
  }
  if (!!config?.authBasicCredentials) {
    axiosConfig.auth = config.authBasicCredentials;
  }
  if (!!config?.proxyConfig) {
    axiosConfig.proxy = config.proxyConfig;
  }
  return axiosConfig;
}

function errorFactory(e: any): HttpErrorModel {
  // TODO Tratar se o erro não é desse tipo e traduzir para o mesmo
  const code = e?.response?.status ?? e?.code ?? 0;
  const data = e?.response?.data;

  const errorKeys = ['error', 'mensagemDesenvolvedor', 'erro'];
  const messageKeys = ['message', 'mensagemUsuario', 'mensagem'];

  let error: string =
    objectFindValueFromSomeKey(data, errorKeys) ?? 'core.error';
  let message: string =
    objectFindValueFromSomeKey(data, messageKeys) ??
    e?.message ??
    'Xii, Um erro inesperado aconteceu.';

  if (Array.isArray(data)) {
    error = data
      .map((datum: { error: string | undefined }) =>
        objectFindValueFromSomeKey(datum, errorKeys),
      )
      .join('|');
    message = data
      .map((datum: { message: string | undefined }) =>
        objectFindValueFromSomeKey(datum, messageKeys),
      )
      .join('\n');
  }

  return new HttpErrorModel(message, code, error);
}

function interceptorRegistry(
  axiosInstance: AxiosInstance,
  keycloak?: KeycloakInstance,
): void {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(errorFactory(error)),
  );

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = keycloak?.token;
      if (!!token) {
        config.headers.Authorization = `bearer ${token}`;
      }
      const protectedRequest =
        !!config?.withCredentials ||
        !!config?.auth ||
        !!config?.headers?.Authorization;
      log(
        'HTTP%s',
        `${protectedRequest ? ' 🔐 ' : ''} ${
          config?.method?.toUpperCase?.() ?? ''
        }`,
        config.url,
      );
      return config;
    },
    (error) => Promise.reject(errorFactory(error)),
  );
}

function requestUrlWithParams(
  url: string,
  params: RequestParams = new Map(),
): string {
  const paramMap = params instanceof Map ? params : objectToMap(params);
  if (paramMap?.size > 0) {
    let paramCounter = url.includes('?') ? 1 : 0;
    paramMap?.forEach((value, key) => {
      if (value !== undefined) {
        url += paramCounter++ > 0 ? '&' : '?';
        url += `${key}=${encodeURIComponent(value.toString())}`;
      }
    });
  }
  return url;
}

function requestGet<Response>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<Response> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.get<Response>(url);
    return result;
  };
}

function requestGetWithHeaders<Data>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<ResponseWithHeaders<Data>> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.get<Data>(url);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Data>;
  };
}

function requestGetText(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<string> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.get<string>(url, {
      responseType: 'text',
    });
    return result;
  };
}

function requestGetTextWithHeaders(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<ResponseWithHeaders<string>> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.get<string>(url, {
      responseType: 'text',
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<string>;
  };
}

function requestGetBlob(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<Blob> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.get<Blob>(url, {
      responseType: 'blob',
    });
    return result;
  };
}

function requestGetBlobWithHeaders(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<ResponseWithHeaders<Blob>> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.get<Blob>(url, {
      responseType: 'blob',
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Blob>;
  };
}

function requestDelete<Response>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<Response> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.delete<Response>(url);
    return result;
  };
}

function requestDeleteWithHeaders<Data>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<ResponseWithHeaders<Data>> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.delete<Data>(url);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Data>;
  };
}

function requestHead<Response>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<Response> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.head<Response>(url);
    return result;
  };
}

function requestHeadWithHeaders<Data>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<ResponseWithHeaders<Data>> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.head<Data>(url);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Data>;
  };
}

function requestOptions<Response>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<Response> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.options<Response>(url);
    return result;
  };
}

function requestOptionsWithHeaders<Data>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithoutBodyType<ResponseWithHeaders<Data>> {
  return async (url: string, params: RequestParams = new Map()) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.options<Data>(url);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Data>;
  };
}

function requestPost<Response>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithBodyType<Response> {
  return async (
    url: string,
    body: any = {},
    params: RequestParams = new Map(),
  ) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.post<Response>(url, body);
    return result;
  };
}

function requestPostWithHeaders<Data>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithBodyType<ResponseWithHeaders<Data>> {
  return async (
    url: string,
    body: any = {},
    params: RequestParams = new Map(),
  ) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.post<Data>(url, body);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Data>;
  };
}

function requestPostBlob<Blob>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithBodyType<Blob> {
  return async (
    url: string,
    body: any = {},
    params: RequestParams = new Map(),
  ) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.post<Blob>(url, body, {
      responseType: 'blob',
    });
    return result;
  };
}

function requestPostBlobWithHeaders<Blob>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithBodyType<ResponseWithHeaders<Blob>> {
  return async (
    url: string,
    body: any = {},
    params: RequestParams = new Map(),
  ) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.post<Blob>(url, body, {
      responseType: 'blob',
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Blob>;
  };
}

function requestPut<Response>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithBodyType<Response> {
  return async (
    url: string,
    body: any = {},
    params: RequestParams = new Map(),
  ) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.put<Response>(url, body);
    return result;
  };
}

function requestPutWithHeaders<Data>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithBodyType<ResponseWithHeaders<Data>> {
  return async (
    url: string,
    body: any = {},
    params: RequestParams = new Map(),
  ) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.put<Data>(url, body);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Data>;
  };
}

function requestPatch<Response>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithBodyType<Response> {
  return async (
    url: string,
    body: any = {},
    params: RequestParams = new Map(),
  ) => {
    url = requestUrlWithParams(url, params);
    const { data: result } = await axiosInstance.patch<Response>(url, body);
    return result;
  };
}

function requestPatchWithHeaders<Data>(
  axiosInstance: AxiosInstance,
): HookHttpRequestWithBodyType<ResponseWithHeaders<Data>> {
  return async (
    url: string,
    body: any = {},
    params: RequestParams = new Map(),
  ) => {
    url = requestUrlWithParams(url, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { headers, data } = await axiosInstance.patch<Data>(url, body);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = { headers, data };
    return result as ResponseWithHeaders<Data>;
  };
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type HookHttpHeadersType = { [key: string]: string };
export type HookHttpBaseUrlType = string;
export type HookHttpWithCredentialsType = boolean;
export type HookHttpAuthBasicCredentialsType = AxiosBasicCredentials;
export type HookHttpProxyConfigType = AxiosProxyConfig;
export type HookHttpInjectTokenType = boolean;

export type HookHttpRequestWithoutBodyType<Response> = (
  url: string,
  params: RequestParams,
) => Promise<Response>;

export type HookHttpRequestWithBodyType<Response> = (
  url: string,
  body: any,
  params: RequestParams,
) => Promise<Response>;

export interface ResponseWithHeaders<Data> {
  headers: { [key: string]: string };
  data?: Data;
}

export interface HookHttpInterface {
  httpGet<Response>(url: string, params?: RequestParams): Promise<Response>;
  httpGetWithHeaders<Data>(
    url: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  httpGetText(url: string, params?: RequestParams): Promise<string>;
  httpGetTextWithHeaders(
    url: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<string>>;
  httpGetBlob(url: string, params?: RequestParams): Promise<Blob>;
  httpGetBlobWithHeaders(
    url: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Blob>>;
  httpDelete<Response>(url: string, params?: RequestParams): Promise<Response>;
  httpDeleteWithHeaders<Data>(
    url: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  httpHead<Response>(url: string, params?: RequestParams): Promise<Response>;
  httpHeadWithHeaders<Data>(
    url: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  httpOptions<Response>(url: string, params?: RequestParams): Promise<Response>;
  httpOptionsWithHeaders<Data>(
    url: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  httpPost<Response>(
    url: string,
    body?: any,
    params?: RequestParams,
  ): Promise<Response>;
  httpPostWithHeaders<Data>(
    url: string,
    body?: any,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  httpPostBlob(url: string, body?: any, params?: RequestParams): Promise<Blob>;
  httpPostBlobWithHeaders(
    url: string,
    body?: any,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Blob>>;
  httpPut<Response>(
    url: string,
    body?: any,
    params?: RequestParams,
  ): Promise<Response>;
  httpPutWithHeaders<Data>(
    url: string,
    body?: any,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  httpPatch<Response>(
    url: string,
    body?: any,
    params?: RequestParams,
  ): Promise<Response>;
  httpPatchWithHeaders<Data>(
    url: string,
    body?: any,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
}

export interface HookHttpConfigInterface {
  headers?: HookHttpHeadersType;
  baseUrl?: HookHttpBaseUrlType;
  withCredentials?: HookHttpWithCredentialsType;
  authBasicCredentials?: HookHttpAuthBasicCredentialsType;
  proxyConfig?: HookHttpProxyConfigType;
  injectToken?: HookHttpInjectTokenType;
}

export function useHttp(config?: HookHttpConfigInterface): HookHttpInterface {
  const axiosConfig = configFactory(config);

  const axiosInstance = Axios.create(axiosConfig);

  const { keycloak } = useAuth();

  React.useEffect(
    () =>
      interceptorRegistry(
        axiosInstance,
        !!config?.injectToken ? keycloak : undefined,
      ),
    [axiosInstance, config?.injectToken, keycloak],
  );

  return {
    httpGet: React.useCallback(requestGet(axiosInstance), []),
    httpGetWithHeaders: React.useCallback(
      requestGetWithHeaders(axiosInstance),
      [],
    ),
    httpGetText: React.useCallback(requestGetText(axiosInstance), []),
    httpGetTextWithHeaders: React.useCallback(
      requestGetTextWithHeaders(axiosInstance),
      [],
    ),
    httpGetBlob: React.useCallback(requestGetBlob(axiosInstance), []),
    httpGetBlobWithHeaders: React.useCallback(
      requestGetBlobWithHeaders(axiosInstance),
      [],
    ),
    httpDelete: React.useCallback(requestDelete(axiosInstance), []),
    httpDeleteWithHeaders: React.useCallback(
      requestDeleteWithHeaders(axiosInstance),
      [],
    ),
    httpHead: React.useCallback(requestHead(axiosInstance), []),
    httpHeadWithHeaders: React.useCallback(
      requestHeadWithHeaders(axiosInstance),
      [],
    ),
    httpOptions: React.useCallback(requestOptions(axiosInstance), []),
    httpOptionsWithHeaders: React.useCallback(
      requestOptionsWithHeaders(axiosInstance),
      [],
    ),
    httpPost: React.useCallback(requestPost(axiosInstance), []),
    httpPostWithHeaders: React.useCallback(
      requestPostWithHeaders(axiosInstance),
      [],
    ),
    httpPostBlob: React.useCallback(requestPostBlob(axiosInstance), []),
    httpPostBlobWithHeaders: React.useCallback(
      requestPostBlobWithHeaders(axiosInstance),
      [],
    ),
    httpPut: React.useCallback(requestPut(axiosInstance), []),
    httpPutWithHeaders: React.useCallback(
      requestPutWithHeaders(axiosInstance),
      [],
    ),
    httpPatch: React.useCallback(requestPatch(axiosInstance), []),
    httpPatchWithHeaders: React.useCallback(
      requestPatchWithHeaders(axiosInstance),
      [],
    ),
  };
}
