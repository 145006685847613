import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import { BaSeTheme } from '../../theme';
import { BaSeTooltip } from '../alerts/tooltip/tooltip';
import { BaSeIcon } from '../image/icon';
import { BaSeSmall1 } from '../typography/small/small1';
import { BaSeStepContent } from './step-content';
import { StepProps } from './stepper';
import {
  FadableItem,
  StepActionProps,
  StepBehaviorProps,
  StepCircleHeader,
  StepColorProps,
  StepContainer,
  StepContentTransitionHorizontal,
  StepItem,
  StepOrientationProps,
  StepperContent,
  StepText,
  TypeTransitionProps,
} from './stepper-styled';

export interface StepHeaderProps
  extends StepColorProps,
    StepOrientationProps,
    StepActionProps,
    StepBehaviorProps {
  steps: StepProps[];
  title?: string | string[];
  tooltip?: 'none' | 'top' | 'bottom' | 'left' | 'right';
  currentStep: number;
  isDisabled?: boolean;
  transition: TypeTransitionProps;
  onActivated: () => void;
  onStepChange: (step: number) => void;
}

interface StepperContentToRenderProps {
  orientation?: 'horizontal' | 'vertical';
  transition: TypeTransitionProps;
  steps: StepProps[];
  currentStep: number;
  onStepChange(step: number): void;
  preserveContent?: boolean;
}

export const StepperContentToRender: React.FC<StepperContentToRenderProps> = ({
  orientation,
  transition,
  steps,
  currentStep,
  onStepChange,
  preserveContent = false,
}) => {
  const content = React.useMemo(
    () => steps[currentStep - 1].content,
    [steps, currentStep],
  );

  return (
    <>
      {typeof content === 'function' && (
        <StepperContent
          as="section"
          className="BaSe--stepper-content"
          orientation={orientation}
          transition={transition}
        >
          <StepContentTransitionHorizontal transition={transition}>
            <BaSeStepContent
              steps={steps}
              content={content}
              canGotoNext={
                currentStep < steps.length &&
                (!steps?.[currentStep]?.isDisabled ?? true)
              }
              canGotoPrev={
                currentStep > 1 &&
                (!steps?.[currentStep - 2]?.isDisabled ?? true)
              }
              gotoStep={onStepChange}
              onNext={() => onStepChange(currentStep + 1)}
              onPrev={() => onStepChange(currentStep - 1)}
              currentStep={currentStep}
              preserveContent={preserveContent}
            />
          </StepContentTransitionHorizontal>
        </StepperContent>
      )}
    </>
  );
};

export const BaSeStepHeader: React.FC<StepHeaderProps> = ({
  steps,
  transition,
  title,
  tooltip = 'none',
  currentStep,
  isActive,
  isCompleted,
  activeColor = BaSeTheme.colors.institucionais.azulSebrae36,
  completedColor = BaSeTheme.colors.feedbackInterface.sucesso45,
  uncompletedColor = BaSeTheme.colors.institucionais.cinzaSebrae60,
  orientation,
  isDisabled = false,
  isClickable = true,
  onActivated,
  onStepChange,
  activeWithErrorColor = BaSeTheme.colors.feedbackInterface.atencao35,
  completedWithErrorColor = BaSeTheme.colors.feedbackInterface.erro45,
  uncompletedWithErrorColor = BaSeTheme.colors.feedbackInterface.atencao55,
  withError = false,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const itemRef = React.useRef<HTMLButtonElement>(null);

  const { getMessage } = React.useContext(BaSeI18nContext);

  const stepIconDescription = withError
    ? 'stepCurrent.erro'
    : 'stepCurrent.description';

  const renderIcon = React.useCallback(
    (iconName: string, iconColor: string) => {
      return (
        <FadableItem>
          <BaSeIcon
            name={iconName}
            description={getMessage(
              stepIconDescription,
              currentStep.toString(),
            )}
            color={iconColor}
            colorTransition="fast"
            size={24}
          />
        </FadableItem>
      );
    },
    [currentStep, getMessage],
  );

  const renderText = React.useCallback(
    (stringOrList?: string | string[]) => {
      const texts = !Array.isArray(stringOrList)
        ? [stringOrList]
        : stringOrList;

      return (
        <StepText
          isActive={isActive}
          isCompleted={isCompleted}
          activeColor={activeColor}
          completedColor={completedColor}
          uncompletedColor={uncompletedColor}
          orientation={orientation}
          activeWithErrorColor={activeWithErrorColor}
          completedWithErrorColor={completedWithErrorColor}
          uncompletedWithErrorColor={uncompletedWithErrorColor}
          withError={withError}
        >
          <div>
            {texts.map(
              (text, index) =>
                text && <BaSeSmall1 key={index}>{text}</BaSeSmall1>,
            )}
          </div>
        </StepText>
      );
    },
    [
      isActive,
      isCompleted,
      orientation,
      activeColor,
      completedColor,
      uncompletedColor,
    ],
  );

  return (
    <StepContainer
      isActive={isActive}
      isCompleted={isCompleted}
      activeColor={activeColor}
      completedColor={completedColor}
      uncompletedColor={uncompletedColor}
      orientation={orientation}
      activeWithErrorColor={activeWithErrorColor}
      completedWithErrorColor={completedWithErrorColor}
      uncompletedWithErrorColor={uncompletedWithErrorColor}
      withError={withError}
    >
      <StepItem
        ref={itemRef}
        isActive={isActive}
        isCompleted={isCompleted}
        activeColor={activeColor}
        completedColor={completedColor}
        uncompletedColor={uncompletedColor}
        activeWithErrorColor={activeWithErrorColor}
        completedWithErrorColor={completedWithErrorColor}
        uncompletedWithErrorColor={uncompletedWithErrorColor}
        withError={withError}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        disabled={isDisabled}
        isClickable={isClickable}
        onClick={() => onActivated()}
        role={isClickable ? 'button' : 'heading'}
        tabIndex={isClickable ? 0 : -1}
        orientation={orientation}
      >
        <StepCircleHeader
          activeColor={activeColor}
          completedColor={completedColor}
          uncompletedColor={uncompletedColor}
          isActive={isActive}
          isCompleted={isCompleted}
          activeWithErrorColor={activeWithErrorColor}
          completedWithErrorColor={completedWithErrorColor}
          uncompletedWithErrorColor={uncompletedWithErrorColor}
          withError={withError}
        >
          {isCompleted
            ? renderIcon(
                withError ? 'close' : 'check',
                withError ? completedWithErrorColor : completedColor,
              )
            : withError
              ? renderIcon('exclamation', uncompletedWithErrorColor)
              : renderText(currentStep?.toString?.())}
        </StepCircleHeader>
        {renderText(title)}
      </StepItem>

      {tooltip !== 'none' && title && (
        <BaSeTooltip
          buddyRef={itemRef}
          color="outlined"
          direction={tooltip}
          message={title}
          open={isTooltipOpen}
        />
      )}

      {isActive && orientation === 'vertical' && (
        <StepperContentToRender
          orientation={orientation}
          transition={transition}
          steps={steps}
          currentStep={currentStep}
          onStepChange={onStepChange}
        />
      )}
    </StepContainer>
  );
};

BaSeStepHeader.displayName = 'BaSeStepHeader';
