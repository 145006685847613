import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { BaSeScrollbarCss } from '../../theme/scrollbar';
import { mapBaSeSelectColor } from './map-select-style';
import { WrapperOptionsProps } from './select-styled';

interface OptionPickerBoxWrapperProps extends WrapperOptionsProps {
  hasBorderTop?: boolean;
}

interface OptionPickerListUlProps {
  maxHeight: string;
  withFilterText: boolean;
}

export const OptionPickerBoxWrapper = styled.div<OptionPickerBoxWrapperProps>`
  display: flex;
  flex-direction: column;
  padding: 0;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  position: ${({ menuAlwaysIsOpen }) =>
    menuAlwaysIsOpen ? 'static' : 'absolute'};
  width: 100%;
  box-sizing: border-box;
  z-index: ${BaSeTheme.stackOrder.overlay};
  background-color: ${BaSeTheme.colors.defaultColors.white};
  border: 1px solid
    ${({ hasError, border, menuAlwaysIsOpen }) =>
      hasError
        ? mapBaSeSelectColor.error.borderColor
        : menuAlwaysIsOpen
          ? mapBaSeSelectColor['default'].borderColor
          : border};
  border-top: none;
  border-radius: 0px 0px 0.25em 0.25em;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  margin-top: ${({ isOpen }) => (isOpen ? '-3px' : '0')};
  border-width: ${({ isOpen }) => (isOpen ? '1px' : '0')};
  box-shadow: ${({ isOpen, hasError, boxShadowFocus, menuAlwaysIsOpen }) =>
    isOpen
      ? hasError
        ? BaSeTheme.shadows.focusError
        : menuAlwaysIsOpen
          ? 'none'
          : `0 0 0.25em ${boxShadowFocus}`
      : 'none'};

  ${({ hasBorderTop, hasError, border }) =>
    hasBorderTop &&
    css`
      border-top: 1px solid
        ${hasError ? mapBaSeSelectColor.error.borderColor : border};
      border-top-left-radius: 0.25em;
      border-top-right-radius: 0.25em;
      top: calc(100% + 0.5em);
    `}

  ${BaSeScrollbarCss}
`;

export const OptionPickerFilterContainer = styled.div`
  padding: 0.75em 0.5em 0.5em 0.5em;
  border-bottom: 1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
`;

export const OptionPickerHeaderLabelContainer = styled.div`
  padding: 0.5em 0.75em;
  border-bottom: 1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
`;

export const OptionPickerOuterList = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    overflow: hidden;
    height: ${isOpen ? 'auto' : '0'};
    opacity: ${isOpen ? '1' : '0'};
    transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
    box-sizing: border-box;
    position: relative;
  `}
`;

export const OptionPickerListUl = styled.ul<OptionPickerListUlProps>`
  ${({ maxHeight, withFilterText }) => css`
    padding: 0;
    margin: ${withFilterText ? '1em 0' : '0.5em 0 1em'};
    width: 100%;
    max-height: ${maxHeight};
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
  `}
`;

export const OptionPickerExtraActionsHeaderWrapper = styled.div`
  border-top: 1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  border-bottom: 1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  padding: 0.5em 0.75em;
`;

export const OptionPickerExtraActionButton = styled.button<{ isLast: boolean }>`
  background-color: ${BaSeTheme.colors.defaultColors.white};
  padding: 0.5em 0.75em;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-left-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
    `}

  &:hover,
  &:focus,
  &:active {
    background-color: ${BaSeTheme.colors.institucionais.azulSebrae97};
  }

  &:focus {
    font-weight: bold;
  }
`;

export const OptionPickerInfoWrapper = styled.div`
  width: 90%;
`;

export const OptionPickerComplementWrapper = styled.div`
  word-wrap: unset;
  white-space: nowrap;
`;

export const OptionPickerDetailCloseButton = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
`;
