import * as React from 'react';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeInputElement } from '../../../elements/input-element';
import { useDebounce } from '../../../hooks/debounce';
import { BaSeTheme } from '../../../theme';
import {
  BaSeShapeButton,
  ShapeButtonProps,
} from '../../button/shape-button/shape-button';
import { BaSeInput } from '../../input/text/input';
import { DataInterfaceProps } from '../data-styled';
import { DataFilterAreaProps } from '../filter-area/filter-area';
import {
  DataTextFilterContainerProps,
  DataTextFilterWrapper,
  DataTextFilterWrapperProps,
  IconButton,
  TextFilterContainer,
} from './text-filter-styled';

export interface TextFilterEventProps<Filter>
  extends Pick<
    DataFilterAreaProps<Filter>,
    'setFilter' | 'setTags' | 'resetSelection'
  > {
  term?: string;
}

export interface DataTextFilterProps<Filter>
  extends Pick<
      DataInterfaceProps,
      'highlightedColor' | 'foregroundColor' | 'textColor'
    >,
    Pick<DataTextFilterWrapperProps, 'textFilterWidth'>,
    Pick<
      DataFilterAreaProps<Filter>,
      'onClearFilter' | 'setFilter' | 'setTags' | 'resetSelection'
    >,
    DataTextFilterContainerProps {
  hasTextFilter?: boolean;
  textFilterPlaceholder?: string;
  textFilterInitialValue?: string;
  textFilterOnTyping?: boolean;
  textFilterIconProps?: Partial<
    Omit<ShapeButtonProps, 'onClick' | 'autoFocus'>
  >;
  clearFilterButtonClicked: number;
  onTextFilter?(textFilterEventProps: TextFilterEventProps<Filter>): void;
}

export function BaSeDataTextFilter<Filter>({
  textFilterPlaceholder,
  textFilterIconProps,
  clearFilterButtonClicked,
  textFilterInitialValue = '',
  textFilterAlwaysVisible = false,
  textFilterOnTyping = true,
  textFilterWidth = 250,
  highlightedColor = BaSeTheme.colors.institucionais.azulSebrae,
  foregroundColor = BaSeTheme.colors.institucionais.cinzaSebrae60,
  textColor = BaSeTheme.colors.institucionais.cinzaSebrae30,
  setFilter,
  setTags,
  resetSelection,
  onClearFilter,
  onTextFilter,
}: React.PropsWithoutRef<DataTextFilterProps<Filter>>): JSX.Element {
  const [textFilterVisible, setTextFilterVisible] = React.useState(
    textFilterAlwaysVisible,
  );
  const [textFilterInputValue, setTextFilterInputValue] = React.useState(
    textFilterInitialValue,
  );
  const [color, setColor] = React.useState(foregroundColor);

  const delay = React.useMemo(
    () => (textFilterOnTyping ? 350 : 0),
    [textFilterOnTyping],
  );

  const textFilterValue = useDebounce(textFilterInputValue, delay);

  const { getMessage } = React.useContext(BaSeI18nContext);
  const textFilterInputRef = React.createRef<BaSeInputElement>();

  const cleanAndClose = React.useCallback(() => {
    if (textFilterValue) {
      setTextFilterInputValue('');
    }
    setTextFilterVisible(textFilterAlwaysVisible);
  }, [textFilterValue, textFilterAlwaysVisible]);

  const sendFilter = React.useCallback(
    (text?: string) => {
      const term = text?.trim?.();

      if (typeof onTextFilter === 'function') {
        onTextFilter({ setFilter, setTags, resetSelection, term });
      } else {
        setFilter((term || undefined) as unknown as Filter);
      }

      if (
        clearFilterButtonClicked &&
        !term &&
        typeof onClearFilter === 'function'
      ) {
        onClearFilter({ resetSelection });
      }
    },
    [onTextFilter, onClearFilter, setFilter, setTags, clearFilterButtonClicked],
  );

  React.useEffect(() => {
    if (!textFilterAlwaysVisible && textFilterVisible) {
      textFilterInputRef.current?.focus();
    }
  }, [textFilterVisible]);

  React.useEffect(() => {
    if (clearFilterButtonClicked) {
      cleanAndClose();
    }
  }, [clearFilterButtonClicked]);

  React.useEffect(() => {
    if (textFilterOnTyping) {
      sendFilter(textFilterValue);
    }
  }, [textFilterValue, textFilterOnTyping]);

  return (
    <DataTextFilterWrapper
      textFilterVisible={textFilterVisible}
      textFilterWidth={textFilterWidth}
    >
      <TextFilterContainer textFilterAlwaysVisible={textFilterAlwaysVisible}>
        <BaSeInput
          ref={textFilterInputRef}
          size="small"
          placeholder={
            textFilterPlaceholder ??
            getMessage('dataFilter.textFilterPlaceholder')
          }
          iconButton={{
            color,
            name: textFilterInputValue ? 'close' : 'search',
            typeButton: 'base-icon',
            action: () => cleanAndClose(),
          }}
          onSpecificKeyDown={[
            { listenerKey: 'Esc', callback: () => cleanAndClose() },
            {
              listenerKey: 'Enter',
              callback: () => sendFilter(textFilterValue),
            },
          ]}
          color={color}
          value={textFilterInputValue}
          onFocus={() => setColor(highlightedColor)}
          onBlur={() => {
            setColor(foregroundColor);
            if (!textFilterInputValue) {
              setTextFilterVisible(textFilterAlwaysVisible);
            }
          }}
          onChange={(value) => {
            setColor(textFilterInputValue ? highlightedColor : foregroundColor);
            setTextFilterInputValue(value);
          }}
        />
      </TextFilterContainer>
      {!textFilterAlwaysVisible && (
        <IconButton>
          <BaSeShapeButton
            size="small"
            sizeIcon="small"
            nameIcon="search"
            type="tertiary"
            color={foregroundColor}
            tooltip={{
              size: 'small',
              direction: 'top',
              messageNoBreakLine: true,
              color: textColor,
              message:
                textFilterPlaceholder ??
                getMessage('dataFilter.textFilterPlaceholder'),
            }}
            onClick={() => setTextFilterVisible(true)}
            {...textFilterIconProps}
          />
        </IconButton>
      )}
    </DataTextFilterWrapper>
  );
}
