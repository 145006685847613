import * as React from 'react';
import ReactCropper, {
  centerCrop,
  Crop,
  makeAspectCrop,
} from 'react-image-crop';
import { BaSeI18nContext } from '../../contexts/i18n';
import { ButtonProps } from '../button/button-props';
import { BaSeButton } from '../button/button/button';
import { Image } from '../file/image-picker';
import {
  CropperActionsButton,
  CropperContainer,
  CropperContainerProps,
} from './cropper-styled';

export interface ImageEncodeOptions {
  quality?: number;
  type?: string;
}

export interface CropperInterface extends CropperContainerProps {
  file?: Image;
  circularCrop?: boolean;
  cropAspect?: number;
  cropSaveButtonProps?: Partial<ButtonProps>;
  cropCancelButtonProps?: Partial<ButtonProps>;
  cropMinWidth?: number;
  cropMinHeight?: number;
  cropMaxWidth?: number;
  cropMaxHeight?: number;
  saveCallback: (file: Image) => void;
  cancelCallback: (param?: string) => void;
  onCropError?: (message: string) => void;
}

interface CropInitialValuesArgs extends Pick<Crop, 'unit'> {
  size: number;
}
function cropInitialValues({ unit, size }: CropInitialValuesArgs): Crop {
  return {
    unit,
    x: 0,
    y: 0,
    width: size,
    height: size,
  };
}

interface CropInitialAfterLoadImageArgs {
  width: number;
  height: number;
  aspect?: number;
}
function cropInitialAfterLoadImage({
  width,
  height,
  aspect,
}: CropInitialAfterLoadImageArgs): Crop {
  if (aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        width,
        height,
      ),
      width,
      height,
    );
  }

  return centerCrop({ unit: 'px', width, height }, width, height);
}

function valueOrMax(value: number, max?: number): number {
  return max !== undefined ? (value > max ? max : value) : value;
}

export const BaSeCropper: React.FC<CropperInterface> = ({
  file,
  circularCrop,
  cropAspect,
  cropSaveButtonProps,
  cropCancelButtonProps,
  cropFocusColor,
  cropMinWidth,
  cropMinHeight,
  cropMaxWidth,
  cropMaxHeight,
  saveCallback,
  cancelCallback,
  onCropError,
}) => {
  const [crop, setCrop] = React.useState<Crop>(
    cropInitialValues({ unit: '%', size: 100 }),
  );
  const [completedCrop, setCompletedCrop] = React.useState<Crop>(
    cropInitialValues({ unit: 'px', size: 0 }),
  );
  const [imageUrl, setImageUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const imageRef = React.useRef<HTMLImageElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const { getMessage } = React.useContext(BaSeI18nContext);

  const handleLoadImageUrl = React.useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      const { width: imageWidth, height: imageHeight } = event.currentTarget;

      const width = valueOrMax(imageWidth, cropMaxWidth);
      const height = valueOrMax(imageHeight, cropMaxHeight);

      const centralizedCrop = cropInitialAfterLoadImage({
        width,
        height,
        aspect: cropAspect,
      });
      setCrop(centralizedCrop);
      setCompletedCrop(centralizedCrop);
    },
    [cropAspect, cropMaxWidth, cropMaxHeight],
  );

  const handleError = React.useCallback(
    (message: string) => {
      // eslint-disable-next-line no-console
      console.error('[BaSeCropper] %s', message);
      setIsLoading(false);
      return onCropError?.(getMessage('cropper.error.crop'));
    },
    [getMessage, onCropError],
  );

  const handleSaveBlob = React.useCallback(
    (blob: Blob | null) => {
      if (!blob) {
        return handleError('Canvas vazio!');
      }

      // const imageEncodeOptions = { type: "image/jpeg", quality: {0, 0.1, ..., 0.9, 1} };
      // const imageEncodeOptions: ImageEncodeOptions = { type: 'image/png' };
      // const image = new File(
      //   [blob],
      //   originalImgTag?.name ??
      //     getMessage('cropper.file.default.name', Date?.now()),
      //   imageEncodeOptions,
      // );

      saveCallback(blob);
      setIsLoading(false);
    },
    [handleError, saveCallback],
  );

  const handleSaveImage = React.useCallback(() => {
    setIsLoading(true);

    const image = imageRef.current;
    if (!image) {
      return handleError('Não encontrou a imagem para recortar');
    }

    const pixelRatio = window.devicePixelRatio;

    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;
    const scaleX = imageWidth / image.width;
    const scaleY = imageHeight / image.height;
    const centerY = imageHeight / 2;
    const centerX = imageWidth / 2;

    const toPx = (value: number, size: number) =>
      completedCrop.unit === 'px' ? value : (value / 100) * size;

    const cropX = toPx(completedCrop.x, image.width) * scaleX;
    const cropY = toPx(completedCrop.y, image.height) * scaleY;
    const cropWidth = toPx(completedCrop.width, image.width) * scaleX;
    const cropHeight = toPx(completedCrop.height, image.height) * scaleY;

    const canvas = canvasRef.current;
    if (!canvas) {
      return handleError('Não encontrou o canvas para recortar');
    }

    canvas.width = Math.floor(cropWidth * pixelRatio);
    canvas.height = Math.floor(cropHeight * pixelRatio);

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return handleError('Não encontrou o contexto 2D do canvas para recortar');
    }

    ctx.imageSmoothingQuality = 'high';
    ctx.save();
    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.rotate(0);
    ctx.scale(1, 1);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      imageWidth,
      imageHeight,
      0,
      0,
      imageWidth,
      imageHeight,
    );
    ctx.restore();

    canvas.toBlob(handleSaveBlob);
  }, [completedCrop, handleError, handleSaveBlob]);

  React.useEffect(() => {
    let url: string;
    if (file) {
      url = URL.createObjectURL(file as File | Blob);
      setImageUrl(url);
    }
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
        setImageUrl('');
      }
    };
  }, [file]);

  return (
    <CropperContainer cropFocusColor={cropFocusColor}>
      {file && imageUrl && (
        <>
          <ReactCropper
            ruleOfThirds={true}
            minWidth={cropMinWidth}
            minHeight={cropMinHeight}
            maxWidth={cropMaxWidth}
            maxHeight={cropMaxHeight}
            crop={crop}
            aspect={cropAspect}
            circularCrop={circularCrop}
            onChange={(_, percentageCrop) => setCrop(percentageCrop)}
            onComplete={(pixelCrop) => setCompletedCrop(pixelCrop)}
          >
            <img
              className="BaSe--cropper-image"
              ref={imageRef}
              src={imageUrl}
              alt={getMessage('cropper.image.alt')}
              onLoad={handleLoadImageUrl}
            />
          </ReactCropper>
          <canvas className="BaSe--cropper-canvas" ref={canvasRef}></canvas>
          <CropperActionsButton>
            <BaSeButton
              {...cropSaveButtonProps}
              type={cropSaveButtonProps?.type ?? 'primary'}
              value={
                cropSaveButtonProps?.value ?? getMessage('cropper.button.save')
              }
              onClick={(clickEvent: React.MouseEvent<HTMLButtonElement>) => {
                cropSaveButtonProps?.onClick?.(clickEvent);
                handleSaveImage();
              }}
              isLoading={isLoading}
            />
            <BaSeButton
              {...cropCancelButtonProps}
              type={cropCancelButtonProps?.type ?? 'secondary'}
              value={
                cropCancelButtonProps?.value ??
                getMessage('cropper.button.cancel')
              }
              onClick={(clickEvent: React.MouseEvent<HTMLButtonElement>) => {
                cropCancelButtonProps?.onClick?.(clickEvent);
                cancelCallback();
              }}
            />
          </CropperActionsButton>
        </>
      )}
    </CropperContainer>
  );
};

BaSeCropper.displayName = 'BaSeCropper';
