import * as React from 'react';

type ConditionKey = number | 'other';

type ConditionValue = JSX.Element | string;

type Condition = {
  [condition in ConditionKey]: ConditionValue;
};

interface PluralizationProps {
  value: number;
  when: Condition;
}

export const Pluralization: React.FC<PluralizationProps> = ({
  value,
  when,
}) => <>{when?.[value] ?? when?.other}</>;

Pluralization.displayName = 'SebraePluralization';
