import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { BaSeTab, TabProps } from './tab';
import { BaSeTabContent, TabContentProps, GotoQuery } from './tab-content';
import {
  TabContent,
  TabHeader,
  TabsContainer,
  TabSeparator,
  TypeTransitionProps,
} from './tabs-styled';

export type Tabs = (Pick<TabProps, 'isDisabled' | 'title'> &
  Pick<TabContentProps, 'content'>)[];

interface TabsProps extends Pick<TabProps, 'activeColor' | 'inactiveColor'> {
  tabs: Tabs;
  activatedTabIndex?: number;
  tabGap?: string | number;
  withTransition?: boolean;
  preserveContent?: boolean;
  onActivatedTabIndex: (tabIndex: number) => void;
}

export const BaSeTabs: React.FC<TabsProps> = ({
  tabs,
  activatedTabIndex = 0,
  tabGap = '1.5em',
  withTransition = false,
  preserveContent = false,
  activeColor = BaSeTheme.colors.institucionais.azulSebrae36,
  inactiveColor = BaSeTheme.colors.institucionais.cinzaSebrae45,
  onActivatedTabIndex,
}: TabsProps) => {
  const tabsRef = React.createRef<HTMLUListElement>();
  const [lastActivatedTabIndex, setLastActivatedTabIndex] =
    React.useState(activatedTabIndex);
  const [activatedTabLeft, setActivatedTabLeft] = React.useState(0);
  const [activatedTabWidth, setActivatedTabWidth] = React.useState(0);
  const [destakTabLeft, setDestakTabLeft] = React.useState(0);
  const [destakTabWidth, setDestakTabWidth] = React.useState(0);
  const [transition, setTransition] =
    React.useState<TypeTransitionProps>('inert');

  if (!Array.isArray(tabs) || tabs?.length === 0) {
    throw Error('Prop "tabs" é obrigatória');
  }

  if (!onActivatedTabIndex) {
    throw Error('Prop "onActivatedTabIndex" é obrigatório');
  }

  React.useEffect(() => {
    onActivatedTabIndex(activatedTabIndex);

    return () => setLastActivatedTabIndex(activatedTabIndex);
  }, [activatedTabIndex, onActivatedTabIndex]);

  React.useEffect(() => {
    if (withTransition) {
      setTransition(() =>
        lastActivatedTabIndex < activatedTabIndex
          ? 'leftToRight'
          : 'rightToLeft',
      );
      setTimeout(
        () => setTransition('inert'),
        BaSeTheme.transitions.durationNormalInMilliseconds,
      );
    }
  }, [activatedTabIndex, lastActivatedTabIndex, withTransition]);

  const findIndex = React.useCallback(
    (query: GotoQuery) => {
      return (
        query?.withIndex ??
        tabs.findIndex((tab) => tab.title === query?.withTitle)
      );
    },
    [tabs],
  );

  return (
    <TabsContainer className="BaSe--tabs">
      <TabHeader
        ref={tabsRef}
        tabGap={tabGap}
        onScroll={() => {
          setActivatedTabLeft(
            (actual) => actual - (tabsRef?.current?.scrollLeft ?? 0),
          );
        }}
      >
        {tabs.map(({ title, isDisabled }, index) => (
          <React.Fragment key={index}>
            <BaSeTab
              title={title}
              index={index}
              activeColor={activeColor}
              inactiveColor={inactiveColor}
              isActive={activatedTabIndex === index}
              isDisabled={isDisabled}
              onActivatedTabIndex={(tabIndex) => {
                onActivatedTabIndex(tabIndex);
              }}
              onActiveItem={(tabLeft, tabWidth) => {
                setActivatedTabLeft(tabLeft);
                setActivatedTabWidth(tabWidth);
              }}
              onDestakItem={(tabLeft, tabWidth) => {
                setDestakTabLeft(tabLeft);
                setDestakTabWidth(tabWidth);
              }}
            />
          </React.Fragment>
        ))}
      </TabHeader>
      <TabSeparator
        activeLeft={activatedTabLeft}
        activeWidth={activatedTabWidth}
        activeColor={activeColor}
        destakLeft={destakTabLeft}
        destakWidth={destakTabWidth}
      />
      {preserveContent ? (
        tabs.map(({ content }, index) => (
          <React.Fragment key={index}>
            <TabContent
              as="section"
              className="BaSe--tab-content"
              transition={transition}
              hidden={index !== activatedTabIndex}
            >
              <BaSeTabContent
                canGotoTab={(query) =>
                  !tabs?.[findIndex(query)]?.isDisabled ?? false
                }
                gotoTab={(query) => onActivatedTabIndex(findIndex(query))}
                content={content}
              />
            </TabContent>
          </React.Fragment>
        ))
      ) : (
        <TabContent
          as="section"
          className="BaSe--tab-content"
          transition={transition}
        >
          <BaSeTabContent
            canGotoTab={(query) =>
              !tabs?.[findIndex(query)]?.isDisabled ?? false
            }
            gotoTab={(query) => onActivatedTabIndex(findIndex(query))}
            content={tabs[activatedTabIndex]?.content}
          />
        </TabContent>
      )}
    </TabsContainer>
  );
};

BaSeTabs.displayName = 'BaSeTabs';
