import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeTheme } from '../../theme';
import { OptionsTriangle } from '../a11y-bar/a11y-bar-styled';
import { BaSeIconButton } from '../button/shape-button/icon-button';
import { BaSeHeading5 } from '../typography/heading/heading5';
import { BaSeSmall1 } from '../typography/small/small1';
import { BaSeSingleSolution, SingleSolutionProps } from './single-solution';
import {
  InformationWrapper,
  SolutionHeader,
  SolutionsGrid,
  SolutionsMenu,
  SolutionsWrapper,
} from './user-apps-styled';

export interface SolutionsProps {
  solutions: SingleSolutionProps[];
  title?: string;
  iconColor?: string;
  titleColor?: string;
  textColor?: string;
  iconName?: string;
  closeMenuColor?: string;
  menuBackgroundColor?: string;
  menuBorderColor?: string;
  description?: string;
}

const SOLUTIONS_SIZE = 'small';
const SOLUTIONS_TRIANGLE_POSITION = 'calc(100% - 3.5em)';

const { white } = BaSeTheme.colors.defaultColors;
const { azulSebrae97, azulSebrae, cinzaSebrae45 } =
  BaSeTheme.colors.institucionais;

export const BaSeUserApps: React.FC<SolutionsProps> = ({
  iconColor = white,
  iconName = 'apps',
  menuBackgroundColor = white,
  menuBorderColor = azulSebrae97,
  textColor = cinzaSebrae45,
  titleColor = azulSebrae,
  closeMenuColor = 'default',
  solutions = [],
  title,
  description,
}) => {
  const SolutionsRef = React.useRef<HTMLDivElement>(null);

  const { getMessage } = React.useContext(BaSeI18nContext);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const handleClick = React.useCallback(() => {
    setMenuOpen(true);
  }, []);

  useOutsideEvent(SolutionsRef, setMenuOpen);

  return (
    <SolutionsWrapper ref={SolutionsRef}>
      <BaSeIconButton
        descriptionIcon={getMessage('solutions.title')}
        type="tertiary"
        nameIcon={iconName}
        color={iconColor}
        sizeIcon={SOLUTIONS_SIZE}
        onClick={handleClick}
      />
      <SolutionsMenu
        isVisible={menuOpen}
        background={menuBackgroundColor}
        border={menuBorderColor}
      >
        <OptionsTriangle
          isVisible={menuOpen}
          background={menuBackgroundColor}
          border={menuBorderColor}
          left={SOLUTIONS_TRIANGLE_POSITION}
        />
        <SolutionHeader>
          <BaSeHeading5 color={titleColor}>
            {title ?? getMessage('solutions.title')}
          </BaSeHeading5>
          <BaSeIconButton
            nameIcon="close-big"
            descriptionIcon={getMessage('buttonIcon.iconDescription.close')}
            type="tertiary"
            size="small"
            color={closeMenuColor}
            onClick={() => setMenuOpen(false)}
          />
        </SolutionHeader>
        {!!description && (
          <InformationWrapper>
            <BaSeSmall1 color={textColor}>{description}</BaSeSmall1>
          </InformationWrapper>
        )}
        <SolutionsGrid>
          {solutions.map((solu) => (
            <React.Fragment key={solu.title}>
              <BaSeSingleSolution
                {...solu}
                isVisible={menuOpen}
                textColor={textColor}
              />
            </React.Fragment>
          ))}
        </SolutionsGrid>
      </SolutionsMenu>
    </SolutionsWrapper>
  );
};

BaSeUserApps.displayName = 'BaSeUserApps';
