import * as React from 'react';
import { TypographyProps } from '../typography-props';
import { BaSeTypographyWrapper } from '../typography-wrapper';
import { StyledHeading3 } from './heading3-styled';

export const BaSeHeading3: React.FC<TypographyProps> = (props) => (
  <BaSeTypographyWrapper
    fontSize={1.72}
    lineHeight={1.2}
    wrapper={StyledHeading3}
    {...props}
  >
    {props.children}
  </BaSeTypographyWrapper>
);

BaSeHeading3.displayName = 'BaSeHeading3';
