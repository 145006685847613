import { transformLightness } from './../../utils/color-transformation/transform-lightness';
import { ThemeColorValue } from './../../theme/theme-interface';
import styled, { css, keyframes } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { transformToFilter } from '../../utils/color-transformation/transform-to-filter';
import { numberWithUnit } from '../../utils/string-utils';

function lighting(color: string, lightness: number = 0.97): string {
  return transformLightness(color, lightness);
}

function darking(color: string, lightness: number = 0.25): string {
  return transformLightness(color, lightness);
}

export type TabItemProps = TapControlProps &
  Pick<TabColorProps, 'inactiveColor' | 'activeColor'>;

export type TypeTransitionProps = 'leftToRight' | 'rightToLeft' | 'inert';
export interface TabTransitionProps {
  transition: TypeTransitionProps;
}
export interface TapControlProps {
  isActive: boolean;
}
export interface TabColorProps {
  activeColor?: ThemeColorValue;
  inactiveColor?: ThemeColorValue;
}

export interface LinesProps extends Pick<TabColorProps, 'activeColor'> {
  activeLeft: number;
  activeWidth: number;
  destakLeft: number;
  destakWidth: number;
}

export const sidePadding = 8;

const slideIn = (origin: number) => keyframes`
  from {
    display: block;
    transform: translateX(${origin}%);
    opacity: 0.9;
  }
  to {
    display: block;
    opacity: 1;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabSeparator = styled.hr<LinesProps>`
  margin: 0;
  width: 100%;
  height: 1px;
  border: none;
  overflow: visible;
  position: relative;
  background-color: ${BaSeTheme.colors.institucionais.cinzaSebrae90};

  &::before,
  &::after {
    content: '';
    top: 0;
    left: 0;
    height: 4px;
    position: absolute;
    background: ${({ activeColor }) => activeColor};
    z-index: ${BaSeTheme.stackOrder.normal};
  }

  &::before {
    transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
    will-change: transform, width;
    ${({ activeLeft, activeWidth }) => css`
      transform: translateX(${activeLeft}px);
      width: ${activeWidth}px;
    `}
  }

  &::after {
    ${({ destakLeft, destakWidth }) => css`
      transform: translateX(${destakLeft}px);
      width: ${destakWidth}px;
    `};
  }
`;

export const TabHeader = styled.ul<{ tabGap: string | number }>`
  margin: 0;
  padding: 0;
  display: flex;
  gap: ${({ tabGap }) => numberWithUnit(tabGap)};
  align-items: flex-end;
  position: relative;

  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  flex-shrink: 0;
  min-block-size: fit-content;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabContent = styled.div<TabTransitionProps>`
  position: relative;
  &[hidden] {
    display: none;
  }
  ${({ transition }) =>
    transition !== 'inert' &&
    css`
      animation: ${slideIn(transition === 'leftToRight' ? -100 : 100)}
        ${BaSeTheme.transitions.durationNormal}
        ${BaSeTheme.transitions.continuousTimingFunction};
    `};
`;

export const TabContainer = styled.li<TapControlProps>``;

export const TabItem = styled.button<TabItemProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: ${BaSeTheme.typography.lineHeightBase};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  outline: none;
  border: none;
  margin: 3px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: ${sidePadding}px;
  white-space: nowrap;
  scroll-snap-align: start;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ isActive, inactiveColor }) =>
    isActive
      ? css`
          box-shadow: 0 0 0 3px ${lighting(inactiveColor ?? '')};
          background-color: ${lighting(inactiveColor ?? '')};
        `
      : css`
          background-color: ${BaSeTheme.colors.defaultColors.transparent};
        `}

  &:hover {
    background-color: ${({ inactiveColor, disabled }) =>
      !disabled && lighting(inactiveColor ?? '')};

    ${({ isActive, disabled, inactiveColor, activeColor }) =>
      !isActive &&
      !disabled &&
      css`
        h5 {
          color: ${darking(activeColor ?? '')};
        }
        .BaSe--icon {
          filter: ${transformToFilter(darking(activeColor ?? ''))};
        }
        box-shadow: 0 0 0 3px ${lighting(inactiveColor ?? '')};
      `};
  }

  &:focus {
    box-shadow: 0 0 0 3px
      ${({ inactiveColor }) => lighting(inactiveColor ?? '', 0.9)};

    ${({ isActive }) =>
      isActive &&
      css`
        &:not(:hover) {
          background-color: ${BaSeTheme.colors.defaultColors.transparent};
        }
      `}
  }
`;

export const CustomTabItem = styled.button<TabItemProps>`
  appearance: none;
  outline: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  scroll-snap-align: start;
  padding: 0;
  margin: 3px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  box-shadow: 0 0 0 3px
    ${({ isActive, activeColor }) =>
      isActive ? activeColor : BaSeTheme.colors.defaultColors.transparent};

  &:hover {
    ${({ isActive, disabled, inactiveColor }) =>
      isActive
        ? css`
            opacity: 0.8;
          `
        : !disabled &&
          css`
            box-shadow: 0 0 0 3px ${lighting(inactiveColor ?? '', 0.9)};
          `}
  }

  &:focus {
    box-shadow: 0 0 0 3px
      ${({ isActive, activeColor, inactiveColor }) =>
        isActive ? activeColor : lighting(inactiveColor ?? '', 0.9)};
  }
`;
