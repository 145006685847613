import { BaSeConfig as config } from './config/config';
export { BaSeConfig } from './config/config';

export { version as BaSeVersion } from '../package.json';

export * from './public-api/components';
export * from './public-api/elements';
export * from './public-api/hooks';
export * from './public-api/others';
export * from './public-api/pages';

export const BaSeHomePage = config.docsUrl;
