import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import { A11yCtrlProps, useA11yCtrl } from '../../hooks/a11y-ctrl';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeLocale, BaSeLocaleWithLanguages } from '../../i18n';
import { BaSeTheme } from '../../theme';
import {
  ThemeBreakpointType,
  ThemeColorValue,
} from '../../theme/theme-interface';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import { BaSeButton } from '../button/button/button';
import { BaSeIconButton } from '../button/shape-button/icon-button';
import { BaSeContainer } from '../container/container';
import { BaSeIcon } from '../image/icon';
import { BaSeLogoSebraeRestrict } from '../image/logo-sebrae/logo-sebrae-restrict';
import { BaSeLogoSebraeRestrictInverse } from '../image/logo-sebrae/logo-sebrae-restrict-inverse';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import { BaSeSmall1 } from '../typography/small/small1';
import { BaSeSmall2 } from '../typography/small/small2';
import {
  AccessButton,
  AcessButtonPrefix,
  BarWrapper,
  ContrastControl,
  ContrastLevel,
  GridAcessButton,
  InvisibleButton,
  ItemSeparator,
  ItemWrapper,
  LanguageList,
  OptionFlex,
  OptionsTriangle,
  OptionsWrapper,
} from './a11y-bar-styled';

export type A11yLocaleLanguage = [string, string];

export interface A11yBarProps extends A11yCtrlProps {
  delimitedOnBreakpoint?: ThemeBreakpointType;
  backgroundColor?: ThemeColorValue;
  titleColor?: ThemeColorValue;
  foregroundColor?: ThemeColorValue;
  borderColor?: ThemeColorValue;
  optionsBackgroundColor?: ThemeColorValue;
  optionsForegroundColor?: ThemeColorValue;
  disabledOptionBackgroundColor?: ThemeColorValue;
  disabledOptionPrimaryColor?: ThemeColorValue;
  closeMenuColor?: string;
  iconColor?: ThemeColorValue;
  logoInverseColor?: boolean;
  localeLanguageOptions?: A11yLocaleLanguage[];
  useDefaultChangeLocaleLanguage?: boolean;
  localeLanguageColorOption?: ThemeColorValue;
  onChangeLocaleLanguage?(localeLanguage: A11yLocaleLanguage): void;
  logoOnClick?(
    e:
      | React.MouseEvent<HTMLImageElement, MouseEvent>
      | React.KeyboardEvent<HTMLImageElement>,
  ): void;
  renderLogo?(): JSX.Element;
}

const {
  azulSebrae,
  cinzaSebrae97,
  cinzaSebrae90,
  cinzaSebrae75,
  cinzaSebrae30,
  azulSebrae90,
} = BaSeTheme.colors.institucionais;
const { white } = BaSeTheme.colors.defaultColors;

const locales = Object.keys(BaSeLocaleWithLanguages);
const localeWithLanguages = Object.entries(BaSeLocaleWithLanguages);

export const BaSeA11yBar: React.FC<A11yBarProps> = ({
  delimitedOnBreakpoint,
  backgroundColor = cinzaSebrae90,
  titleColor = azulSebrae,
  foregroundColor = cinzaSebrae30,
  optionsBackgroundColor = white,
  optionsForegroundColor = azulSebrae,
  disabledOptionBackgroundColor = cinzaSebrae97,
  disabledOptionPrimaryColor = cinzaSebrae75,
  iconColor = cinzaSebrae75,
  logoInverseColor = false,
  localeLanguageOptions = localeWithLanguages,
  borderColor = azulSebrae90,
  closeMenuColor = azulSebrae,
  // localeLanguageOptions = [],
  useDefaultChangeLocaleLanguage = true,
  localeLanguageColorOption,
  onChangeLocaleLanguage,
  logoOnClick,
  renderLogo,
  ...a11yCtrl
}) => {
  const { getMessage, changeLocale } = React.useContext(BaSeI18nContext);

  const a11yOptionsRef = React.createRef<HTMLDivElement>();
  const localeLanguageOptionsRef = React.createRef<HTMLDivElement>();

  const [selectedLocaleLanguage, setSelectedLocaleLanguage] =
    React.useState<A11yLocaleLanguage>();
  const [shownA11yOptions, setShownA11yOptions] = React.useState(false);
  const [shownLocaleLanguageOptions, setShownLocaleLanguageOptions] =
    React.useState(false);

  const [selectedLocale, selectedLanguage] = React.useMemo(
    () => selectedLocaleLanguage ?? [undefined, undefined],
    [selectedLocaleLanguage],
  );

  const {
    contrastModeIndicator,
    toggleContrastMode,
    toggleLibras,
    zoomIn,
    zoomOut,
  } = useA11yCtrl(a11yCtrl);

  const focusColors = React.useMemo(
    () => ColorMapper.createTransparent(optionsForegroundColor, 0.6, 0.14),
    [optionsForegroundColor],
  );

  const displayContrastLevel = React.useCallback(() => {
    return (
      <>
        {contrastModeIndicator.map((cont, key) => (
          <ContrastLevel
            key={key}
            background={cont === 'x' ? optionsForegroundColor : 'transparent'}
            border={cont === 'x' ? optionsForegroundColor : iconColor}
          />
        ))}
      </>
    );
  }, [contrastModeIndicator, iconColor, optionsForegroundColor]);

  const getColor = React.useCallback(
    (
      isDisabled: boolean,
      defaultColor: ThemeColorValue,
      disabledColor: ThemeColorValue,
    ) => (isDisabled ? disabledColor : defaultColor),
    [],
  );

  useOutsideEvent(a11yOptionsRef, setShownA11yOptions);
  useOutsideEvent(localeLanguageOptionsRef, setShownLocaleLanguageOptions);

  React.useEffect(() => {
    if (!localeLanguageOptions.length) {
      return;
    }

    if (!useDefaultChangeLocaleLanguage || !selectedLocale) {
      return;
    }

    if (locales.includes(selectedLocale)) {
      changeLocale(selectedLocale as BaSeLocale);
    }
  }, [
    localeLanguageOptions,
    selectedLocale,
    changeLocale,
    useDefaultChangeLocaleLanguage,
  ]);

  React.useEffect(() => {
    if (!selectedLocaleLanguage) {
      return;
    }
    onChangeLocaleLanguage?.(selectedLocaleLanguage);
  }, [selectedLocaleLanguage, onChangeLocaleLanguage]);

  React.useEffect(() => {
    if (!localeLanguageOptions.length) {
      return;
    }

    setSelectedLocaleLanguage(localeLanguageOptions[0]);
  }, [localeLanguageOptions]);

  return (
    <BarWrapper backgroundColor={backgroundColor}>
      <BaSeContainer
        delimitedOnBreakpoint={delimitedOnBreakpoint}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ItemWrapper>
          <ItemWrapper ref={a11yOptionsRef}>
            <BaSeIcon
              name="accessibility"
              size="1.25em"
              color={iconColor}
              description={getMessage(
                'icon.label',
                getMessage('a11yBar.accessibility'),
              )}
            />
            <BaSeSmall1 isBold={true} color={foregroundColor}>
              {getMessage('a11yBar.accessibility')}
            </BaSeSmall1>
            <BaSeIconButton
              nameIcon="angle-down"
              descriptionIcon={getMessage('buttonIcon.iconDescription.options')}
              color={iconColor}
              type={shownA11yOptions ? 'primary' : 'tertiary'}
              size="small"
              onClick={() => setShownA11yOptions((i) => !i)}
            />
            <OptionsWrapper
              isVisible={shownA11yOptions}
              border={borderColor}
              background={optionsBackgroundColor}
            >
              <OptionsTriangle
                isVisible={shownA11yOptions}
                border={borderColor}
                background={optionsBackgroundColor}
              />
              <OptionFlex>
                <BaSeParagraph isBold={true} color={titleColor}>
                  {getMessage('a11yBar.accessibility')}
                </BaSeParagraph>
                <BaSeIconButton
                  nameIcon="close-big"
                  descriptionIcon={getMessage(
                    'buttonIcon.iconDescription.close',
                  )}
                  type="tertiary"
                  size="small"
                  color={closeMenuColor}
                  onClick={() => setShownA11yOptions(false)}
                />
              </OptionFlex>
              <GridAcessButton>
                <AccessButton
                  disabledBackgroundColor={disabledOptionBackgroundColor}
                  disabledPrimaryColor={disabledOptionPrimaryColor}
                  type="button"
                  background={optionsBackgroundColor}
                  primaryColor={optionsForegroundColor}
                  activeColor={focusColors}
                  isDisabled={!onChangeLocaleLanguage}
                  onClick={() => toggleContrastMode()}
                >
                  <AcessButtonPrefix isVisible={true}>
                    <ContrastControl>{displayContrastLevel()}</ContrastControl>
                  </AcessButtonPrefix>
                  <BaSeIcon
                    description="contraste"
                    name="constrast"
                    color={getColor(
                      !onChangeLocaleLanguage,
                      optionsForegroundColor,
                      disabledOptionPrimaryColor,
                    )}
                    size={16}
                  />
                  <BaSeSmall2
                    color={getColor(
                      !onChangeLocaleLanguage,
                      optionsForegroundColor,
                      disabledOptionPrimaryColor,
                    )}
                  >
                    Alto contraste
                  </BaSeSmall2>
                </AccessButton>
                <AccessButton
                  disabledBackgroundColor={disabledOptionBackgroundColor}
                  disabledPrimaryColor={disabledOptionPrimaryColor}
                  type="button"
                  background={optionsBackgroundColor}
                  primaryColor={optionsForegroundColor}
                  activeColor={focusColors}
                  isDisabled={false}
                  onClick={() => toggleLibras()}
                >
                  <AcessButtonPrefix isVisible={false} />
                  <BaSeIcon
                    description="contraste"
                    name="libras-1"
                    color={optionsForegroundColor}
                    size={16}
                  />
                  <BaSeSmall2 color={optionsForegroundColor}>Libras</BaSeSmall2>
                </AccessButton>
                <AccessButton
                  disabledBackgroundColor={disabledOptionBackgroundColor}
                  disabledPrimaryColor={disabledOptionPrimaryColor}
                  type="button"
                  background={optionsBackgroundColor}
                  primaryColor={optionsForegroundColor}
                  activeColor={focusColors}
                  isDisabled={false}
                  onClick={() => zoomIn()}
                >
                  <AcessButtonPrefix isVisible={false} />
                  <BaSeIcon
                    description="contraste"
                    name="search-plus"
                    color={optionsForegroundColor}
                    size={16}
                  />
                  <BaSeSmall2 color={optionsForegroundColor}>zoom +</BaSeSmall2>
                </AccessButton>
                <AccessButton
                  disabledBackgroundColor={disabledOptionBackgroundColor}
                  disabledPrimaryColor={disabledOptionPrimaryColor}
                  type="button"
                  background={optionsBackgroundColor}
                  primaryColor={optionsForegroundColor}
                  activeColor={focusColors}
                  isDisabled={false}
                  onClick={() => zoomOut()}
                >
                  <AcessButtonPrefix isVisible={false} />
                  <BaSeIcon
                    description="contraste"
                    name="search-minus"
                    color={optionsForegroundColor}
                    size={16}
                  />
                  <BaSeSmall2 color={optionsForegroundColor}>zoom -</BaSeSmall2>
                </AccessButton>
              </GridAcessButton>
            </OptionsWrapper>
          </ItemWrapper>
          {selectedLanguage && (
            <>
              <ItemSeparator color={iconColor} />
              <ItemWrapper ref={localeLanguageOptionsRef}>
                <BaSeIcon
                  name="globe"
                  size="1.25em"
                  color={iconColor}
                  description={getMessage('icon.label', selectedLanguage)}
                />

                <BaSeSmall1 isBold={true} color={foregroundColor}>
                  {selectedLanguage}
                </BaSeSmall1>
                <BaSeIconButton
                  nameIcon="angle-down"
                  descriptionIcon={getMessage(
                    'buttonIcon.iconDescription.options',
                  )}
                  color={iconColor}
                  type={shownLocaleLanguageOptions ? 'primary' : 'tertiary'}
                  size="small"
                  onClick={() => setShownLocaleLanguageOptions((i) => !i)}
                />
                <OptionsWrapper
                  hasScroll={true}
                  border={borderColor}
                  isVisible={shownLocaleLanguageOptions}
                  background={optionsBackgroundColor}
                >
                  <OptionsTriangle
                    left="25%"
                    isVisible={shownA11yOptions}
                    border={borderColor}
                    background={optionsBackgroundColor}
                  />
                  <OptionFlex>
                    <BaSeParagraph isBold={true} color={titleColor}>
                      {getMessage('a11yBar.language')}
                    </BaSeParagraph>
                    <BaSeIconButton
                      nameIcon="close-big"
                      descriptionIcon={getMessage(
                        'buttonIcon.iconDescription.close',
                      )}
                      color={closeMenuColor}
                      type="tertiary"
                      size="small"
                      onClick={() => setShownLocaleLanguageOptions(false)}
                    />
                  </OptionFlex>
                  <LanguageList>
                    {localeLanguageOptions.map(([locale, language]) => (
                      // <button
                      //   key={locale}
                      //   type="button"
                      //   onClick={() =>
                      //     setSelectedLocaleLanguage([locale, language])
                      //   }
                      // >
                      //   {language}
                      // </button>
                      <React.Fragment key={language}>
                        <BaSeButton
                          color={localeLanguageColorOption}
                          type="tertiary"
                          value={language}
                          onClick={() =>
                            setSelectedLocaleLanguage([locale, language])
                          }
                        />
                      </React.Fragment>
                    ))}
                  </LanguageList>
                </OptionsWrapper>
              </ItemWrapper>
            </>
          )}
        </ItemWrapper>
        <ItemWrapper>
          {renderLogo ? (
            renderLogo()
          ) : (
            <>
              {logoInverseColor ? (
                <BaSeLogoSebraeRestrictInverse
                  width="0.75em"
                  onClick={logoOnClick}
                />
              ) : (
                <BaSeLogoSebraeRestrict width="0.75em" onClick={logoOnClick} />
              )}
              <InvisibleButton onClick={logoOnClick as React.MouseEventHandler}>
                <BaSeSmall1
                  isBold={true}
                  color={logoInverseColor ? white : azulSebrae}
                >
                  Sebrae
                </BaSeSmall1>
              </InvisibleButton>
            </>
          )}
        </ItemWrapper>
      </BaSeContainer>
    </BarWrapper>
  );
};

BaSeA11yBar.displayName = 'BaSeA11yBar';
