import * as React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { log } from '../utils/log';

export interface NavigateToPositionInterface {
  position?: { left: number; top: number };
}

export interface NavigateToOptionsInterface<T = unknown> {
  keepQueryString?: boolean;
  forceReload?: boolean;
  state?: T;
  scrollTo?: NavigateToPositionInterface | number;
}

export interface ActualUriOptions {
  withOtherRoute?: string;
}

export interface HookRouterInterface {
  getActualUri(actualUriOptions?: ActualUriOptions): string;
  getRouteParam(key: string): string | undefined;
  getQueryString(name: string): string | undefined;
  hasQueryString(name: string): boolean;
  navigateTo(
    routePathOrUrl: string,
    options?: NavigateToOptionsInterface,
  ): void;
  reload(): void;
  isInRoute(routePath: string): boolean;
  isExactRoute(routePath: string): boolean;
}

export function useRouter(): HookRouterInterface {
  let params: any;
  let pageHistory: any;

  try {
    params = useParams();
    pageHistory = useHistory();
  } catch (_error) {
    params = pageHistory = {};
  }

  const getActualUri = React.useCallback(
    (actualUriOptions: ActualUriOptions) => {
      const actualUri = window.location.href;
      if (!actualUriOptions?.withOtherRoute) {
        return actualUri;
      }
      const routeToChange = actualUriOptions.withOtherRoute;
      const actualRoute = window.location.pathname;
      return actualUri.replace(actualRoute, routeToChange);
    },
    [],
  );

  const getQueryString = React.useCallback(
    (name: string, url: string | URL | Location = document.location) => {
      const searchParams = new URL(url as string).searchParams;
      const value = searchParams.get(name);
      if (value) {
        return value;
      }
      return undefined;
    },
    [],
  );

  const hasQueryString = React.useCallback(
    (name: string, url: string | URL | Location = document.location) => {
      const searchParams = new URL(url as string).searchParams;
      return searchParams.has(name);
    },
    [],
  );

  const getRouteParam = React.useCallback(
    (key: string) => {
      const param = params?.[key];
      if (param !== undefined) {
        return decodeURIComponent(param);
      }
      return undefined;
    },
    [params],
  );

  const scrollToPosition = React.useCallback(
    (scrollTo?: NavigateToPositionInterface | number) => {
      if (scrollTo !== undefined) {
        if (typeof scrollTo === 'number') {
          return window.scrollTo(0, scrollTo);
        }
        window.scrollTo(scrollTo.position);
      }
    },
    [],
  );

  const navigateTo = React.useCallback(
    (
      routePathOrUrl: string,
      {
        keepQueryString = false,
        forceReload = false,
        state,
        scrollTo,
      }: NavigateToOptionsInterface = {},
    ) => {
      if (keepQueryString) {
        routePathOrUrl += window.location.search ?? '';
      }

      if (forceReload) {
        routePathOrUrl = window.location.origin + routePathOrUrl;
      }

      const changeHref =
        /^https*:\/\/.*$/.test(routePathOrUrl) ||
        !pageHistory.hasOwnProperty('push');

      if (changeHref) {
        log('Router', `Saindo da URL "${window.location.href}"`);
        log('Router', `Indo para a URL "${routePathOrUrl}"`);
        window.location.href = routePathOrUrl;
        return;
      }
      log('Router', `Saindo da URL "${window.location.href}"`);
      log('Router', `Indo para a rota "${routePathOrUrl}"`);

      const pushedPage = pageHistory.push(routePathOrUrl, state);
      setTimeout(() => scrollToPosition(scrollTo), 100);
      return pushedPage;
    },
    [],
  );

  const reload = React.useCallback(() => {
    window.location.reload();
  }, []);

  const isInRoute = React.useCallback(
    (routeUrl: string) =>
      !!useRouteMatch({
        exact: false,
        path: routeUrl,
      }),
    [],
  );

  const isExactRoute = React.useCallback(
    (routeUrl: string) =>
      !!useRouteMatch({
        exact: true,
        path: routeUrl,
      }),
    [],
  );

  return {
    getActualUri,
    getRouteParam,
    getQueryString,
    hasQueryString,
    navigateTo,
    reload,
    isInRoute,
    isExactRoute,
  };
}
