import * as React from 'react';
import {
  IdType,
  PluginHook,
  Row,
  TableInstance,
  TableState,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { BaSeDataInfoProvider } from '../../contexts/data/info';
import {
  BaSeDataSelectionProvider,
  DataSelectionContextInterface,
} from '../../contexts/data/selection';
import { BaSeI18nContext } from '../../contexts/i18n';
import { BaSeDataElement } from '../../elements/data-element';
import { BaSeTheme } from '../../theme';
import { BaSeActionItem } from '../action-item/action-item';
import {
  BaSeItemProps,
  SingleItemProps,
} from '../action-item/action-item-props';
import { BaSeButton, ButtonProps } from '../button/button/button';
import {
  BaSeShapeButton,
  ShapeButtonProps,
} from '../button/shape-button/shape-button';
import { TagProps } from '../labels/tag/tag';
import { BaSeLoading } from '../loading/loading';
import { BaSeHeading5 } from '../typography/heading/heading5';
import { BaSeHeading6 } from '../typography/heading/heading6';
import {
  DataContainer,
  DataContent,
  DataFilterOrTags,
  DataFooter,
  DataHeader,
  DataHeaderTitle,
  DataInterfaceProps,
  DataLoading,
  DataSelectionHelper,
  DataWrapper,
  spacePixels,
} from './data-styled';
import { configMapper, pageReducedRange } from './data-utils';
import {
  BaSeDataFilterArea,
  DataFilterAreaProps,
} from './filter-area/filter-area';
import {
  BaSeDataPagination,
  DataPaginationProps,
} from './pagination/pagination';
import {
  DataHeaderSelection,
  DataHeaderSelectionProps,
} from './selection/header-selection';
import {
  DataSelectOrUnselectAll,
  DataSelectOrUnselectAllProps,
} from './selection/select-or-unselect-all';
import {
  DataToggleAllCheckbox,
  ToggleAllCheckboxProps,
} from './selection/toggle-all-checkbox';
import {
  DataToggleRowCheckbox,
  ToggleRowCheckboxProps,
} from './selection/toggle-row-checkbox';
import {
  BaSeDataTextFilter,
  DataTextFilterProps,
} from './text-filter/text-filter';

type KeyOf<Item> = keyof Item;

export type DataRenderedCell = number | string | JSX.Element;
export type DataHeader = DataRenderedCell;
export type DataCell = DataRenderedCell;
export type DataPageSize = number;
export type DataPageIndex = number;
export type DataSortType = 'ASC' | 'DESC';
export type DataSortField<Item> = IdType<Item>;
export type DataFetchFilter<Filter> = Filter;

export interface DataFetchPage {
  size?: DataPageSize;
  index?: DataPageIndex;
}

export interface DataFetchSort<Item> {
  type?: DataSortType;
  field?: DataSortField<Item>;
}

export interface DataCellToMapper<Item, Key extends KeyOf<Item>> {
  value: Item[Key];
  data: Item[];
  row: { index: number };
}

export interface DataResetableSelection {
  resetSelection(): void;
}

export interface DataRenderProps<Item extends object, Filter>
  extends DataResetableSelection {
  table: TableInstance<Item>;
  setTags: React.Dispatch<React.SetStateAction<DataFetchFilter<TagProps[]>>>;
  setFilter: React.Dispatch<React.SetStateAction<DataFetchFilter<Filter>>>;
}

export interface DataFetchRequest<Item, Filter> {
  page?: DataFetchPage;
  sort?: DataFetchSort<Item>;
  filter?: DataFetchFilter<Filter>;
}

export interface ReloadDataConfig {
  resetSelection?: boolean;
}

export interface DataReloadable {
  reloadData(reloadDataConfig?: ReloadDataConfig): void;
}

export interface DataArgsToMapper<Item>
  extends DataReloadable,
    DataResetableSelection {
  values: Item;
}

export interface DataArgsToSelectionAction<Item extends object>
  extends DataReloadable,
    Pick<
      DataSelectionContextInterface<Item>,
      'isAllSelected' | 'selectedItems'
    > {}

export interface DataConfig<Item> {
  header: DataHeader;
  accessorKey?: KeyOf<Item>;
  id?: string;
  mapper?(args: DataArgsToMapper<Item>): DataCell;
}

export interface DataGroupConfig<Item> {
  header: DataHeader;
  config: DataConfig<Item>[];
}

export interface DataProps<Item extends object = {}, Filter = any>
  extends Pick<
      DataPaginationProps,
      | 'itemsPerPage'
      | 'compactMode'
      | 'notFoundMessage'
      | 'singularItemDescription'
      | 'pluralItemDescription'
    >,
    Pick<
      DataTextFilterProps<Filter>,
      | 'textFilterPlaceholder'
      | 'textFilterInitialValue'
      | 'textFilterAlwaysVisible'
      | 'textFilterWidth'
      | 'textFilterIconProps'
      | 'textFilterOnTyping'
      | 'onTextFilter'
      | 'hasTextFilter'
    >,
    Pick<
      DataFilterAreaProps<Filter>,
      | 'customFilterActivatedTitle'
      | 'customFilterClearButtonProps'
      | 'customFilterIconProps'
      | 'customFilterIconVisible'
      | 'customFilterTitle'
      | 'customFilterVisible'
      | 'customFilterVisibleWhenHasTags'
      | 'headerBackgroundColor'
      | 'filterAreaBackgroundColor'
      | 'hasCustomFilter'
      | 'onClearFilter'
      | 'renderCustomFilter'
    >,
    Pick<
      DataHeaderSelectionProps<Item>,
      | 'genderOfItemDescription'
      | 'selectionIconActions'
      | 'selectionButtonActions'
    >,
    Pick<
      DataSelectOrUnselectAllProps<Item>,
      | 'hasPagination'
      | 'total'
      | 'selectOrUnselectAllBackgroundColor'
      | 'selectOrUnselectAllHandler'
      | 'hasSelectOrUnselectAll'
    >,
    Pick<ToggleRowCheckboxProps<Item>, 'itemSelectionHandler'>,
    Pick<
      ToggleAllCheckboxProps<Item>,
      'hasToggleAllSelection' | 'toggleAllSelectionHandler'
    >,
    DataInterfaceProps {
  config?: DataConfig<Item>[];
  configActionItems?: SingleItemProps[];
  configActionItemsProps?: Partial<
    Omit<BaSeItemProps, 'onClose' | 'onOpen' | 'items'>
  >;
  groupConfig?: DataGroupConfig<Item>[];
  hasSelection?: boolean;
  hasSorting?: boolean;
  hasHeader?: boolean;
  idAccessorKey?: KeyOf<Item> | 'id';
  initialPageIndex?: DataPageIndex;
  initialPageSize?: DataPageSize;
  initialSortField?: DataSortField<Item>;
  initialSortType?: DataSortType;
  isLoading?: boolean;
  items?: Item[];
  loadingText?: string;
  subTitle?: string;
  title?: string;
  renderNotFoundContent?(args: DataReloadable): JSX.Element;
  extraIconActions?(args: DataReloadable): ShapeButtonProps[];
  extraButtonActions?(args: DataReloadable): ButtonProps[];
  render(props: DataRenderProps<Item, Filter>): JSX.Element;
  whenRenderTextCell(value: string): JSX.Element;
  fetchData?(request: DataFetchRequest<Item, Filter>): void;
}

export const DATA_SELECTION_ID = 'BaSe-data-selection';

const colors = BaSeTheme.colors;

function BaSeDataForwardedRef<Item extends object = {}, Filter = any>(
  {
    // data
    total,
    items = [],
    initialPageSize = 10,
    initialPageIndex = 0,
    initialSortType = 'ASC',
    initialSortField,
    idAccessorKey = 'id',
    itemsPerPage,
    // config
    config,
    configActionItems,
    groupConfig,
    loadingText,
    textFilterPlaceholder,
    textFilterInitialValue,
    textFilterAlwaysVisible,
    textFilterWidth,
    customFilterIconVisible = true,
    customFilterVisibleWhenHasTags = false,
    customFilterVisible: initialCustomFilterVisible = false,
    isLoading = false,
    hasCustomFilter = false,
    hasPagination = false,
    hasSelection = false,
    hasSorting = false,
    hasTextFilter = false,
    // layout
    title,
    subTitle,
    customFilterTitle,
    customFilterActivatedTitle,
    width,
    notFoundMessage,
    pluralItemDescription,
    configActionItemsProps,
    textFilterIconProps,
    textFilterOnTyping,
    customFilterIconProps,
    customFilterClearButtonProps,
    singularItemDescription,
    hasToggleAllSelection = true,
    genderOfItemDescription = 'male',
    compactMode = false,
    hasHeader = true,
    hasSelectOrUnselectAll = true,
    shadow = BaSeTheme.shadows.profundidade4,
    // colors
    highlightedColor = colors.institucionais.azulSebrae,
    foregroundColor = colors.institucionais.cinzaSebrae60,
    backgroundColor = colors.defaultColors.white,
    headerBackgroundColor: externalHeaderBackgroundColor = colors.defaultColors
      .white,
    footerBackgroundColor = colors.defaultColors.white,
    titleColor = colors.institucionais.cinzaSebrae45,
    textColor = colors.institucionais.cinzaSebrae30,
    separatorColor = colors.institucionais.cinzaSebrae90,
    filterAreaBackgroundColor = colors.institucionais.cinzaSebrae97,
    selectOrUnselectAllBackgroundColor = colors.institucionais.cinzaSebrae97,
    // methods
    fetchData,
    render,
    whenRenderTextCell,
    renderCustomFilter,
    onClearFilter,
    onTextFilter,
    renderNotFoundContent,
    itemSelectionHandler,
    toggleAllSelectionHandler,
    selectOrUnselectAllHandler,
    extraIconActions = () => [],
    extraButtonActions = () => [],
    selectionIconActions = () => [],
    selectionButtonActions = () => [],
  }: React.PropsWithoutRef<DataProps<Item, Filter>>,
  forwardedRef?: React.ForwardedRef<BaSeDataElement<Filter>>,
): JSX.Element {
  total ??= items?.length ?? 0;

  if (total === undefined) {
    throw Error('[BaSeData] "total" inválido');
  }

  if (groupConfig && config) {
    throw Error('[BaSeData] Use somente o "config" ou o "groupConfig"');
  }

  if (hasSorting && initialSortField === undefined) {
    throw Error(
      '[BaSeData] Informe o campo inicial que veio ordenado com o "initialSortField"',
    );
  }

  if (hasPagination && fetchData === undefined) {
    throw Error(
      '[BaSeData] Informe o callback "fetchData" para utilizar a paginação',
    );
  }

  if (customFilterVisibleWhenHasTags && customFilterIconVisible) {
    throw Error(
      '[BaSeData] Caso configure que o custom filter só fique visível ' +
        'quando tiver tag, não pode exibir o ícone do custom filter',
    );
  }

  const [configActionItemsColor, setConfigActionItemsColor] =
    React.useState(foregroundColor);
  const [dataPageSize, setDataPageSize] = React.useState(initialPageSize);
  const [fetchAgain, setFetchAgain] = React.useState(0);
  const [containerHeight, setContainerHeight] = React.useState(0);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [isAllFromPageSelected, setIsAllFromPageSelected] =
    React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState(
    items.filter((item) => item?.['isSelected'] ?? false),
  );
  const [filter, setFilter] = React.useState<DataFetchFilter<Filter>>();
  const [tags, setTags] = React.useState<TagProps[]>([]);
  const [clearFilterButtonClicked, setClearFilterButtonClicked] =
    React.useState(0);
  const [customFilterVisible, setCustomFilterVisible] = React.useState(
    initialCustomFilterVisible,
  );

  const { getMessage } = React.useContext(BaSeI18nContext);

  const renderedContainerRef = React.useRef<HTMLDivElement>(null);

  const headerBackgroundColor = React.useMemo(
    () =>
      hasHeader
        ? externalHeaderBackgroundColor
        : colors.defaultColors.transparent,
    [externalHeaderBackgroundColor, hasHeader],
  );

  const dataWrapperShadow = React.useMemo(
    () =>
      compactMode ||
      (backgroundColor === colors.defaultColors.transparent &&
        (headerBackgroundColor === colors.defaultColors.transparent ||
          footerBackgroundColor === colors.defaultColors.transparent))
        ? 'none'
        : shadow,
    [
      compactMode,
      backgroundColor,
      headerBackgroundColor,
      footerBackgroundColor,
      shadow,
    ],
  );

  const [plugins, initialState] = React.useMemo(() => {
    const tableState: Partial<TableState<Item>> = {};
    const pluginHook: PluginHook<Item>[] = [];

    if (hasSorting) {
      tableState.sortBy = [
        {
          id: initialSortField as string,
          desc: initialSortType === 'DESC',
        },
      ];
      pluginHook.push(useSortBy);
    }

    if (hasPagination) {
      tableState.pageIndex = initialPageIndex;
      tableState.pageSize = initialPageSize;
      pluginHook.push(usePagination);
    }

    if (hasSelection) {
      pluginHook.push((hooks) => {
        hooks.visibleColumns.push((cols) => [
          {
            id: DATA_SELECTION_ID,
            Header: ({ rows }) => (
              <DataToggleAllCheckbox
                highlightedColor={highlightedColor}
                rowIdKey={idAccessorKey as KeyOf<Item>}
                actualItems={rows.map((row) => row.original)}
                hasToggleAllSelection={hasToggleAllSelection}
                toggleAllSelectionHandler={toggleAllSelectionHandler}
              />
            ),
            Cell: ({ row }: { row: Row<Item> }) => (
              <DataToggleRowCheckbox
                highlightedColor={highlightedColor}
                rowIdKey={idAccessorKey as KeyOf<Item>}
                rowItem={row.original}
                itemSelectionHandler={itemSelectionHandler}
              />
            ),
          },
          ...cols,
        ]);
      });
    }

    return [pluginHook, tableState];
  }, [
    hasPagination,
    hasSelection,
    hasSorting,
    highlightedColor,
    idAccessorKey,
    initialPageIndex,
    initialPageSize,
    initialSortField,
    initialSortType,
  ]);

  const resetSelection = React.useCallback(() => {
    setIsAllSelected(false);
    setIsAllFromPageSelected(false);
    setSelectedItems([]);
  }, []);

  const reloadData = React.useCallback(
    (reloadDataConfig: ReloadDataConfig = {}) => {
      if (reloadDataConfig?.resetSelection) {
        resetSelection();
      }
      setFetchAgain((counter) => counter + 1);
    },
    [resetSelection],
  );

  const data = React.useMemo(() => items, [items]);

  const columns = React.useMemo(
    () =>
      configMapper({
        config: config ?? [],
        groupConfig,
        reloadData,
        resetSelection,
        whenRenderTextCell,
      }),
    [config, groupConfig, reloadData, resetSelection, whenRenderTextCell],
  );

  const pageCount = React.useMemo(
    () => Math.ceil((total as number) / dataPageSize),
    [total, dataPageSize],
  );

  const tableInstance = useTable<Item>(
    {
      data,
      columns,
      initialState,
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      disableMultiSort: true,
    },
    ...plugins,
  );

  const renderData = React.useCallback(() => {
    if (isLoading) {
      return (
        <DataLoading height={containerHeight}>
          <BaSeLoading
            spinnerSize={64}
            text={loadingText}
            textColor={foregroundColor}
            backgroundColor={backgroundColor}
            spinnerColor={highlightedColor}
          />
        </DataLoading>
      );
    }

    if (!total && typeof renderNotFoundContent === 'function') {
      return renderNotFoundContent({ reloadData });
    }

    return (
      <BaSeDataSelectionProvider<Item>
        hasSelection={hasSelection}
        isAllSelected={isAllSelected}
        setIsAllSelected={setIsAllSelected}
        isAllFromPageSelected={isAllFromPageSelected}
        setIsAllFromPageSelected={setIsAllFromPageSelected}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      >
        <div ref={renderedContainerRef}>
          {render({ table: tableInstance, setFilter, setTags, resetSelection })}
        </div>
      </BaSeDataSelectionProvider>
    );
  }, [
    total,
    isLoading,
    hasSelection,
    renderNotFoundContent,
    render,
    reloadData,
    resetSelection,
    tableInstance,
    isAllSelected,
    isAllFromPageSelected,
    selectedItems,
  ]);

  React.useImperativeHandle(
    forwardedRef,
    () => ({ setFilter, setTags, resetSelection }),
    [resetSelection],
  );

  React.useEffect(() => {
    setContainerHeight(
      renderedContainerRef.current?.getBoundingClientRect().height ?? 300,
    );
  }, [dataPageSize, renderedContainerRef.current]);

  React.useEffect(() => {
    if (hasSelection && isAllSelected) {
      setSelectedItems([]);
    }
  }, [hasSelection, isAllSelected]);

  React.useEffect(() => {
    if (!fetchData) {
      return;
    }

    const pageIndex = tableInstance.state.pageIndex;
    const pageSize = tableInstance.state.pageSize;
    const [sortBy] = tableInstance.state.sortBy ?? [];
    const sortType = (sortBy?.desc ? 'DESC' : 'ASC') as DataSortType;
    const sortField = sortBy?.id as DataSortField<Item>;

    const timeout = setTimeout(
      () =>
        fetchData({
          page: {
            index: pageIndex,
            size: pageSize,
          },
          sort: {
            type: sortType,
            field: sortField,
          },
          filter,
        }),
      15,
    );
    return () => clearTimeout(timeout);
  }, [
    fetchData,
    fetchAgain,
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    tableInstance.state.sortBy,
    filter,
  ]);

  React.useEffect(() => tableInstance?.gotoPage?.(0), [filter]);

  const TitleWithoutSelection = React.useMemo(
    () =>
      function TitleWithoutSelection() {
        return (
          <DataHeaderTitle>
            {title && <BaSeHeading5 color={titleColor}>{title}</BaSeHeading5>}
            {subTitle && (
              <BaSeHeading6 color={foregroundColor}>{subTitle}</BaSeHeading6>
            )}
          </DataHeaderTitle>
        );
      },
    [foregroundColor, subTitle, title, titleColor],
  );

  const TitleWithSelectionAndIconActions = React.useMemo(
    () =>
      function TitleWithSelectionAndIconActions() {
        return isAllSelected ||
          selectedItems.length ||
          (!title && !subTitle) ? (
          <DataHeaderSelection
            total={total}
            foregroundColor={foregroundColor}
            highlightedColor={highlightedColor}
            genderOfItemDescription={genderOfItemDescription}
            pluralItemDescription={pluralItemDescription}
            singularItemDescription={singularItemDescription}
            isAllSelected={isAllSelected}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            reloadData={reloadData}
            selectionIconActions={selectionIconActions}
            selectionButtonActions={selectionButtonActions}
          />
        ) : (
          <TitleWithoutSelection />
        );
      },
    [
      isAllSelected,
      selectedItems,
      foregroundColor,
      genderOfItemDescription,
      pluralItemDescription,
      singularItemDescription,
      subTitle,
      title,
      total,
      reloadData,
      selectionButtonActions,
      selectionIconActions,
    ],
  );

  const Title = React.useMemo(
    () =>
      function Title() {
        return hasSelection ? (
          <TitleWithSelectionAndIconActions />
        ) : (
          <TitleWithoutSelection />
        );
      },
    [hasSelection, TitleWithSelectionAndIconActions, TitleWithoutSelection],
  );

  const Footer = React.useMemo(
    () =>
      function Footer() {
        return !total && typeof renderNotFoundContent === 'function' ? (
          <DataFooter
            verticalPadding={spacePixels}
            separatorColor={colors.defaultColors.transparent}
            footerBackgroundColor={backgroundColor}
          />
        ) : (
          <DataFooter
            horizontalPadding={compactMode ? '0' : spacePixels}
            separatorColor={
              compactMode ? colors.defaultColors.transparent : separatorColor
            }
            footerBackgroundColor={
              compactMode
                ? colors.defaultColors.transparent
                : footerBackgroundColor
            }
          >
            {tableInstance && (
              <BaSeDataPagination<Item>
                notFoundMessage={notFoundMessage}
                singularItemDescription={singularItemDescription}
                pluralItemDescription={pluralItemDescription}
                genderOfItemDescription={genderOfItemDescription}
                compactMode={compactMode}
                highlightedColor={highlightedColor}
                foregroundColor={foregroundColor}
                itemsPerPage={itemsPerPage}
                total={total as number}
                canNextPage={tableInstance.canNextPage}
                canPreviousPage={tableInstance.canPreviousPage}
                page={tableInstance.page}
                pageCount={tableInstance.pageCount}
                pageIndex={tableInstance.state.pageIndex}
                pageSize={tableInstance.state.pageSize}
                pageReducedRange={pageReducedRange(
                  tableInstance.pageOptions ?? [],
                  tableInstance.state.pageIndex ?? 0,
                )}
                gotoPage={tableInstance.gotoPage}
                nextPage={tableInstance.nextPage}
                previousPage={tableInstance.previousPage}
                setDataPageSize={setDataPageSize}
                setPageSize={tableInstance.setPageSize}
              />
            )}
          </DataFooter>
        );
      },
    [
      total,
      notFoundMessage,
      itemsPerPage,
      foregroundColor,
      highlightedColor,
      singularItemDescription,
      pluralItemDescription,
      genderOfItemDescription,
      backgroundColor,
      compactMode,
      footerBackgroundColor,
      spacePixels,
      tableInstance,
      renderNotFoundContent,
    ],
  );

  const header = React.useCallback(
    () =>
      hasHeader ? (
        <DataHeader
          horizontalPadding={compactMode ? '0' : spacePixels}
          headerBackgroundColor={
            compactMode
              ? colors.defaultColors.transparent
              : headerBackgroundColor
          }
        >
          <Title />

          <div className="BaSe--data-header-actions">
            {hasTextFilter && (
              <BaSeDataTextFilter<Filter>
                highlightedColor={highlightedColor}
                foregroundColor={foregroundColor}
                textFilterPlaceholder={textFilterPlaceholder}
                textFilterInitialValue={textFilterInitialValue}
                textFilterAlwaysVisible={textFilterAlwaysVisible}
                textFilterWidth={textFilterWidth}
                textFilterIconProps={textFilterIconProps}
                textFilterOnTyping={textFilterOnTyping}
                setFilter={setFilter}
                setTags={setTags}
                resetSelection={resetSelection}
                onTextFilter={onTextFilter}
                onClearFilter={onClearFilter}
                clearFilterButtonClicked={clearFilterButtonClicked}
              />
            )}
            {hasCustomFilter && customFilterIconVisible && (
              <BaSeShapeButton
                size="small"
                sizeIcon="small"
                type="tertiary"
                color={customFilterVisible ? highlightedColor : foregroundColor}
                nameIcon="filter"
                tooltip={{
                  size: 'small',
                  direction: 'top',
                  messageNoBreakLine: true,
                  color: textColor,
                  message: getMessage(
                    customFilterVisible
                      ? 'dataFilter.closeFilter'
                      : 'dataFilter.openFilter',
                  ),
                }}
                onClick={() => setCustomFilterVisible((shown) => !shown)}
                {...customFilterIconProps}
              />
            )}
            {extraIconActions?.({ reloadData })?.map((extraIconAction) => (
              <BaSeShapeButton
                key={`${extraIconAction.nameIcon}-${extraIconAction.value}`}
                size="small"
                sizeIcon="small"
                type="tertiary"
                color={foregroundColor}
                {...extraIconAction}
              />
            ))}
            {configActionItems && (
              <BaSeActionItem
                size="small"
                sizeIcon="small"
                icon="sliders-v-alt"
                position="top-right"
                color={configActionItemsColor}
                onOpen={() => setConfigActionItemsColor(highlightedColor)}
                onClose={() => setConfigActionItemsColor(foregroundColor)}
                items={configActionItems.map((configActionItem) =>
                  'color' in configActionItem
                    ? configActionItem
                    : { ...configActionItem, color: foregroundColor },
                )}
                tooltip={{
                  size: 'small',
                  direction: 'top',
                  messageNoBreakLine: true,
                  color: textColor,
                  message: getMessage('dataConfigAction.hint'),
                }}
                {...configActionItemsProps}
              />
            )}
            {extraButtonActions?.({ reloadData })?.map((extraButtonAction) => (
              <BaSeButton
                key={`${extraButtonAction.rightIcon}-${extraButtonAction.value}-${extraButtonAction.leftIcon}`}
                size="small"
                type="tertiary"
                color={foregroundColor}
                {...extraButtonAction}
              />
            ))}
          </div>
        </DataHeader>
      ) : (
        <></>
      ),
    [
      hasHeader,
      textColor,
      compactMode,
      hasTextFilter,
      hasCustomFilter,
      foregroundColor,
      textFilterWidth,
      highlightedColor,
      configActionItems,
      textFilterIconProps,
      textFilterOnTyping,
      customFilterIconProps,
      textFilterPlaceholder,
      configActionItemsProps,
      textFilterInitialValue,
      textFilterAlwaysVisible,
      customFilterIconVisible,
      spacePixels,
      customFilterVisible,
      headerBackgroundColor,
      configActionItemsColor,
      clearFilterButtonClicked,
      setTags,
      setFilter,
      getMessage,
      reloadData,
      onTextFilter,
      onClearFilter,
      extraIconActions,
      extraButtonActions,
    ],
  );

  return (
    <DataContainer width={width}>
      {headerBackgroundColor === colors.defaultColors.transparent && header()}
      <DataWrapper shadow={dataWrapperShadow}>
        {headerBackgroundColor !== colors.defaultColors.transparent && header()}

        <BaSeDataInfoProvider>
          <DataContent
            hasHeader={hasHeader}
            footerBackgroundColor={footerBackgroundColor}
            headerBackgroundColor={headerBackgroundColor}
            backgroundColor={
              compactMode ? colors.defaultColors.transparent : backgroundColor
            }
          >
            {renderData()}
          </DataContent>
        </BaSeDataInfoProvider>

        {hasSelection && (
          <DataSelectionHelper>
            <DataSelectOrUnselectAll
              total={total}
              hasPagination={hasPagination}
              isAllFromPageSelected={isAllFromPageSelected}
              isAllSelected={isAllSelected}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              setIsAllSelected={setIsAllSelected}
              hasSelectOrUnselectAll={hasSelectOrUnselectAll}
              selectOrUnselectAllHandler={selectOrUnselectAllHandler}
              singularItemDescription={singularItemDescription}
              pluralItemDescription={pluralItemDescription}
              compactMode={compactMode}
              genderOfItemDescription={genderOfItemDescription}
              highlightedColor={highlightedColor}
              backgroundColor={backgroundColor}
              textColor={textColor}
              selectOrUnselectAllBackgroundColor={
                selectOrUnselectAllBackgroundColor
              }
            />
          </DataSelectionHelper>
        )}

        {(hasCustomFilter || tags.length > 0) && (
          <DataFilterOrTags>
            <BaSeDataFilterArea<Filter>
              customFilterTitle={customFilterTitle}
              customFilterActivatedTitle={customFilterActivatedTitle}
              hasHeader={hasHeader}
              separatorColor={separatorColor}
              textColor={textColor}
              headerBackgroundColor={headerBackgroundColor}
              filterAreaBackgroundColor={filterAreaBackgroundColor}
              customFilterClearButtonProps={customFilterClearButtonProps}
              customFilterVisible={customFilterVisible}
              customFilterVisibleWhenHasTags={customFilterVisibleWhenHasTags}
              tags={tags}
              setTags={setTags}
              setFilter={setFilter}
              resetSelection={resetSelection}
              renderCustomFilter={renderCustomFilter}
              setClearFilterButtonClicked={setClearFilterButtonClicked}
              onClearFilter={onClearFilter}
            />
          </DataFilterOrTags>
        )}

        {footerBackgroundColor !== colors.defaultColors.transparent && (
          <Footer />
        )}
      </DataWrapper>
      {footerBackgroundColor === colors.defaultColors.transparent && <Footer />}
    </DataContainer>
  );
}

export const BaSeData = React.forwardRef(BaSeDataForwardedRef) as <
  Item extends object = {},
  Filter = any,
>(
  props: DataProps<Item, Filter> & {
    ref?: React.Ref<BaSeDataElement<Filter>>;
  },
) => JSX.Element;

BaSeData['displayName'] = 'BaSeData';
