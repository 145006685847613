import { BaSeTheme } from '../../theme';
import styled, { keyframes } from 'styled-components';
import { ThemeColorValue } from '../../theme/theme-interface';

/* keyframes marching-ants of react crop "react-image-crop/ReactCrop.css" */
const marchingAnts = keyframes`
  0% {
    background-position: 0 0, 0 100%, 0 0, 100% 0;
  }
  to {
    background-position: 20px 0, -20px 100%, 0 -20px, 100% 20px;
  }
`;

/* root variables of react crop "react-image-crop/ReactCrop.css" */
const root = {
  'rc-drag-handle-size': '12px',
  'rc-drag-handle-mobile-size': '24px',
  'rc-drag-handle-bg-colour': 'rgba(0, 0, 0, 0.2)',
  'rc-drag-bar-size': '6px',
  'rc-border-color': 'rgba(255, 255, 255, 0.7)',
  'rc-focus-color': BaSeTheme.colors.institucionais.azulSebrae, //'#0088ff',
};

export interface CropperContainerProps {
  cropFocusColor?: ThemeColorValue;
}

export const CropperContainer = styled.div.attrs({
  className: 'BaSe--cropper',
})<CropperContainerProps>`
  /* begin style react crop "react-image-crop/ReactCrop.css" */
  .ReactCrop {
    position: relative;
    display: inline-block;
    cursor: crosshair;
    max-width: 100%;
  }
  .ReactCrop *,
  .ReactCrop *:before,
  .ReactCrop *:after {
    box-sizing: border-box;
  }
  .ReactCrop--disabled,
  .ReactCrop--locked {
    cursor: inherit;
  }
  .ReactCrop__child-wrapper {
    overflow: hidden;
    max-height: inherit;
  }
  .ReactCrop__child-wrapper > img,
  .ReactCrop__child-wrapper > video {
    display: block;
    max-width: 100%;
    max-height: inherit;
  }
  .ReactCrop:not(.ReactCrop--disabled) .ReactCrop__child-wrapper > img,
  .ReactCrop:not(.ReactCrop--disabled) .ReactCrop__child-wrapper > video {
    touch-action: none;
  }
  .ReactCrop:not(.ReactCrop--disabled) .ReactCrop__crop-selection {
    touch-action: none;
  }
  .ReactCrop__crop-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
  .ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);
    cursor: move;
  }
  .ReactCrop--disabled .ReactCrop__crop-selection {
    cursor: inherit;
  }
  .ReactCrop--circular-crop .ReactCrop__crop-selection {
    border-radius: 50%;
  }
  .ReactCrop--circular-crop .ReactCrop__crop-selection:after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid ${root['rc-border-color']};
    opacity: 0.3;
  }
  .ReactCrop--no-animate .ReactCrop__crop-selection {
    outline: 1px dashed white;
  }
  .ReactCrop__crop-selection:not(
      .ReactCrop--no-animate .ReactCrop__crop-selection
    ) {
    animation: ${marchingAnts} 1s;
    background-image: linear-gradient(to right, #fff 50%, #444 50%),
      linear-gradient(to right, #fff 50%, #444 50%),
      linear-gradient(to bottom, #fff 50%, #444 50%),
      linear-gradient(to bottom, #fff 50%, #444 50%);
    background-size:
      10px 1px,
      10px 1px,
      1px 10px,
      1px 10px;
    background-position:
      0 0,
      0 100%,
      0 0,
      100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    color: #fff;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .ReactCrop__crop-selection:focus {
    outline: 2px solid
      ${({ cropFocusColor }) => cropFocusColor ?? root['rc-focus-color']};
    outline-offset: -1px;
  }
  .ReactCrop--invisible-crop .ReactCrop__crop-mask,
  .ReactCrop--invisible-crop .ReactCrop__crop-selection {
    display: none;
  }
  .ReactCrop__rule-of-thirds-vt:before,
  .ReactCrop__rule-of-thirds-vt:after,
  .ReactCrop__rule-of-thirds-hz:before,
  .ReactCrop__rule-of-thirds-hz:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #fff6;
  }
  .ReactCrop__rule-of-thirds-vt:before,
  .ReactCrop__rule-of-thirds-vt:after {
    width: 1px;
    height: 100%;
  }
  .ReactCrop__rule-of-thirds-vt:before {
    left: 33.3333333333%;
  }
  .ReactCrop__rule-of-thirds-vt:after {
    left: 66.6666666667%;
  }
  .ReactCrop__rule-of-thirds-hz:before,
  .ReactCrop__rule-of-thirds-hz:after {
    width: 100%;
    height: 1px;
  }
  .ReactCrop__rule-of-thirds-hz:before {
    top: 33.3333333333%;
  }
  .ReactCrop__rule-of-thirds-hz:after {
    top: 66.6666666667%;
  }
  .ReactCrop__drag-handle {
    position: absolute;
    width: ${root['rc-drag-handle-size']};
    height: ${root['rc-drag-handle-size']};
    background-color: ${root['rc-drag-handle-bg-colour']};
    border: 1px solid ${root['rc-border-color']};
  }
  .ReactCrop__drag-handle:focus {
    background: ${({ cropFocusColor }) =>
      cropFocusColor ?? root['rc-focus-color']};
  }
  .ReactCrop .ord-nw {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    cursor: nw-resize;
  }
  .ReactCrop .ord-n {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: n-resize;
  }
  .ReactCrop .ord-ne {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    cursor: ne-resize;
  }
  .ReactCrop .ord-e {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    cursor: e-resize;
  }
  .ReactCrop .ord-se {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    cursor: se-resize;
  }
  .ReactCrop .ord-s {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    cursor: s-resize;
  }
  .ReactCrop .ord-sw {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
    cursor: sw-resize;
  }
  .ReactCrop .ord-w {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    cursor: w-resize;
  }
  .ReactCrop__disabled .ReactCrop__drag-handle {
    cursor: inherit;
  }
  .ReactCrop__drag-bar {
    position: absolute;
  }
  .ReactCrop__drag-bar.ord-n {
    top: 0;
    left: 0;
    width: 100%;
    height: ${root['rc-drag-bar-size']};
    transform: translateY(-50%);
  }
  .ReactCrop__drag-bar.ord-e {
    right: 0;
    top: 0;
    width: ${root['rc-drag-bar-size']};
    height: 100%;
    transform: translate(50%);
  }
  .ReactCrop__drag-bar.ord-s {
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${root['rc-drag-bar-size']};
    transform: translateY(50%);
  }
  .ReactCrop__drag-bar.ord-w {
    top: 0;
    left: 0;
    width: ${root['rc-drag-bar-size']};
    height: 100%;
    transform: translate(-50%);
  }
  .ReactCrop--new-crop .ReactCrop__drag-bar,
  .ReactCrop--new-crop .ReactCrop__drag-handle,
  .ReactCrop--fixed-aspect .ReactCrop__drag-bar,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
    display: none;
  }
  @media (pointer: coarse) {
    .ReactCrop .ord-n,
    .ReactCrop .ord-e,
    .ReactCrop .ord-s,
    .ReactCrop .ord-w {
      display: none;
    }
    .ReactCrop__drag-handle {
      width: ${root['rc-drag-handle-mobile-size']};
      height: ${root['rc-drag-handle-mobile-size']};
    }
  }
  /* end style react crop "react-image-crop/ReactCrop.css" */

  position: relative;
  display: grid;
  place-items: center;

  .ReactCrop {
    max-height: 50vh;
    margin: 0 auto;
  }

  .BaSe--cropper-image {
    max-width: 100%;
  }

  .BaSe--cropper-canvas {
    display: none;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }
`;

export const CropperActionsButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;
