import * as React from 'react';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { ThemeNamedColor } from '../../../theme/theme-interface';
import { BaSeProgressCircle } from '../../loading/progress/progress-circle';
import { BaSeHeading6 } from '../../typography/heading/heading6';
import { BaSeParagraph } from '../../typography/paragraph/paragraph';
import {
  StyledMessageAttention,
  StyledMessageConfirmation,
  StyledMessageDefault,
  StyledMessageDestructive,
} from './message-styled';

import { BaSeProgressElement } from '../../../elements/progress-element';
import {
  getAlertColorFromType,
  getMessageColorFromType,
} from '../../../theme/maps-colors';
import { BaSeButton } from '../../button/button/button';
import { BaSeIconButton } from '../../button/shape-button/icon-button';
import { BaSeProgressBar } from '../../loading/progress/progress-bar';

const MessageTypes = {
  default: StyledMessageDefault,
  destructive: StyledMessageDestructive,
  confirmation: StyledMessageConfirmation,
  attention: StyledMessageAttention,
};

export interface MessageProps {
  color?: ThemeNamedColor;
  title?: string;
  message: string | string[];
  showCloseButton?: boolean;
  secondsToClose?: number;
  progressAnimationType?: 'circle' | 'bar';
  action?: string;
  onAction?(): void;
}

const getStyledMessageFromType = (type: ThemeNamedColor) => {
  return MessageTypes[type] ?? StyledMessageDefault;
};

export const BaSeMessage: React.FC<MessageProps> = ({
  color = 'default',
  title,
  message,
  showCloseButton = false,
  secondsToClose = 0,
  progressAnimationType: progressAnimationTypeExternal,
  action,
  onAction,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const progressRef = React.useRef<BaSeProgressElement>(null);
  const messages = React.useMemo(
    () => (Array.isArray(message) ? message : [message]),
    [message],
  );

  const { getMessage } = React.useContext(BaSeI18nContext);

  const progressAnimationType = React.useMemo(
    () => progressAnimationTypeExternal ?? (showCloseButton ? 'circle' : 'bar'),
    [progressAnimationTypeExternal],
  );

  const StyledMessage = React.useMemo(
    () => getStyledMessageFromType(color),
    [color],
  );
  const titleEl = React.useMemo(
    () => title ?? getMessage(`message.${color}`),
    [color, title, getMessage],
  );
  const buttonColor = React.useMemo(
    () => getMessageColorFromType(color).foreground,
    [color],
  );
  const progressIndicatorColor = React.useMemo(
    () => getMessageColorFromType(color).foreground,
    [color],
  );
  const progressTrackColor = React.useMemo(
    () => getMessageColorFromType(color).background,
    [color],
  );

  const progressLoadingDuration = React.useMemo(
    () => secondsToClose * 1000,
    [secondsToClose],
  );

  const closeButton = React.useMemo(() => {
    return (
      <div>
        <BaSeIconButton
          nameIcon="close-big"
          descriptionIcon={getMessage('buttonIcon.iconDescription.close')}
          buttonType="button"
          shape="circle"
          size="small"
          sizeIcon="small"
          type="tertiary"
          color={buttonColor}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    );
  }, [buttonColor, getMessage]);

  const progressCircle = React.useMemo(() => {
    return (
      <BaSeProgressCircle
        ref={progressRef}
        indicatorWidth={3}
        loadingDuration={progressLoadingDuration}
        indicatorColor={progressIndicatorColor}
        trackColor={progressTrackColor}
        size={32}
        trackWidth={3}
        type="emptying"
        closeOnTimeout={true}
        onFinish={() => {
          setIsOpen(false);
        }}
      >
        {closeButton}
      </BaSeProgressCircle>
    );
  }, [
    progressRef,
    progressLoadingDuration,
    progressIndicatorColor,
    progressTrackColor,
  ]);

  const progressBar = React.useMemo(() => {
    return (
      <BaSeProgressBar
        backgroundColor={getAlertColorFromType(color).background}
        foregroundColor={getAlertColorFromType(color).foreground}
        loadingDuration={progressLoadingDuration}
        onFinish={() => setIsOpen(false)}
        ref={progressRef}
      />
    );
  }, [getAlertColorFromType, progressLoadingDuration, progressRef]);

  return (
    <>
      {isOpen && (
        <StyledMessage
          onFocusCapture={() => progressRef.current?.pause()}
          onBlurCapture={() => progressRef.current?.resume()}
          onMouseEnter={() => progressRef.current?.pause()}
          onMouseLeave={() => progressRef.current?.resume()}
        >
          <div>
            <BaSeHeading6 isBold={true}>{titleEl}</BaSeHeading6>
            {messages.map((msg, index) => (
              <BaSeParagraph key={index}>{msg}</BaSeParagraph>
            ))}
          </div>

          {action && (
            <BaSeButton
              buttonType="button"
              size="small"
              type="tertiary"
              color={buttonColor}
              value={action}
              onClick={() => onAction?.()}
            />
          )}

          {(secondsToClose === 0 ||
            (secondsToClose > 0 && progressAnimationType !== 'circle')) &&
            showCloseButton &&
            closeButton}

          {secondsToClose > 0 &&
            progressAnimationType === 'circle' &&
            progressCircle}

          {secondsToClose > 0 && progressAnimationType === 'bar' && progressBar}
        </StyledMessage>
      )}
    </>
  );
};

BaSeMessage.displayName = 'BaSeMessage';
