import * as React from 'react';
import { StepProps } from './stepper';
import { StepperContentBody } from './stepper-styled';
export interface StepRenderContentProps {
  gotoStep: (step: number) => void;
  gotoPrev: () => void;
  gotoNext: () => void;
  canGotoPrev: boolean;
  canGotoNext: boolean;
}

export interface StepContentProps
  extends Pick<
    StepRenderContentProps,
    'canGotoNext' | 'canGotoPrev' | 'gotoStep'
  > {
  content: (props: StepRenderContentProps) => JSX.Element;
  onNext: () => void;
  onPrev: () => void;
  steps: StepProps[];
  currentStep: number;
  preserveContent?: boolean;
}

export const BaSeStepContent: React.FC<StepContentProps> = ({
  canGotoNext,
  canGotoPrev,
  content,
  onNext,
  onPrev,
  gotoStep,
  steps,
  preserveContent = false,
  currentStep,
}) => {
  return preserveContent ? (
    <>
      {steps &&
        steps.map((step, index) => {
          return (
            <StepperContentBody key={index} hidden={index + 1 !== currentStep}>
              {step &&
                step.content &&
                step?.content({
                  gotoPrev: onPrev,
                  gotoNext: onNext,
                  canGotoPrev,
                  canGotoNext,
                  gotoStep,
                })}
            </StepperContentBody>
          );
        })}
    </>
  ) : (
    <>
      {content({
        gotoPrev: onPrev,
        gotoNext: onNext,
        canGotoPrev,
        canGotoNext,
        gotoStep,
      })}
    </>
  );
};

BaSeStepContent.displayName = 'BaSeStepContent';
