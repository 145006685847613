import styled from 'styled-components';

export interface SelectedImageProps {
  imageShape?: 'square' | 'circle';
  previewImageFit: 'cover' | 'contain';
}

export const SelectedImage = styled.img.attrs({
  className: 'BaSe--image-picker-selected-image',
  loading: 'lazy',
  decoding: 'async',
})<SelectedImageProps>`
  height: 75px;
  width: 75px;
  min-height: 75px;
  min-width: 75px;
  max-height: 75px;
  max-width: 75px;
  object-fit: ${({ previewImageFit }) => previewImageFit};
  border-radius: ${({ imageShape }) => (imageShape === 'circle' ? '50%' : '0')};
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
